.ng-select.smart-ng-select {
  .ng-select-container {
    border-radius: 2px;
    border: 1px solid $light-grey2;
    min-height: 35px;
    height: 35px;

    .ng-value-container {
      padding-right: 24px;

      .ng-placeholder {
        color: $grey13;
        overflow: auto;
      }

      .ng-value .ng-value-label {
        color: $font-black;
        @include font-autoline-rem(14px);
      }
    }
  }

  &.autocomplete {
    .ng-arrow-wrapper {
      .ng-arrow {
        font-family: smart-icon;
        border: 0;
        right: 8px;

        &::after {
          content: "\e90b";
        }
      }
    }
  }

  &.ng-select-focused,
  &:hover {
    outline: 2px solid $blue4;
    outline-offset: 0;
  }

  &.multi-select-inline-search {
    .ng-value-label {
      max-width: 80%;
    }

    .ng-input {
      height: 20px;
      font-family: "Graphik Regular";
      font-size: 0.875rem;
    }
  }
}

.smart-ng-select {
  .ng-dropdown-panel-items {
    .ng-option {
      @include media-breakpoint-down(sm) {
        height: auto;
      }

      .ng-option-label {
        color: $font-black;
        @include font-line-rem(12px, 18px);

        @include media-breakpoint-down(sm) {
          white-space: normal;
        }
      }
    }
  }
}

ng-dropdown-panel.filter-dropdown-selection {

  .ng-dropdown-panel-items {
    max-height: 150px;

    @include media-breakpoint-down(xs) {
      max-height: 100px;
    }

    .ng-option {
      color: $font-black;
      @include font-line-rem(14px, 21px);
      height: 36px;
      @include rem("padding", 10px);
      border-bottom: 1px solid $grey3;

      @include media-breakpoint-down(sm) {
        height: auto;
      }

      &:hover {
        background-color: $grey !important;
      }

      &.ng-option-selected.ng-option-marked {
        background-color: $grey !important;
      }

      &.ng-option-disabled {
        color: $font-black;
      }
    }

    .ng-option.ng-option-marked {
      background: $grey;
    }

    .ng-option-label {
      @include media-breakpoint-down(sm) {
        white-space: normal;
      }
    }
  }

  .ng-dropdown-header {
    background: $grey6;
    @include rem("padding", 5px);
    position: relative;

    &::before {
      content: "\e90b";
      font-family: "smart-icon";
      position: absolute;
      color: $grey7;
      @include font-line-rem(20px, 30px);
      top: 5px;
      left: 12px;
    }

    input {
      border: none;
      border-radius: 2px;
      @include rem("padding", 5px 10px 5px 35px);
      height: 30px;
      @include font-line-rem(14px, 21px);
      color: $grey7;
    }
  }
}

ng-select.filter-dropdown-selection {
  .ng-select-container {
    border: 1px solid $light-grey2 ;

    .ng-clear-wrapper {
      display: none;
    }

    .ng-value-container {
      .ng-placeholder {
        overflow: auto;
        color: $grey13 !important;
        @include font-line-rem(14px, 21px);
      }
    }
  }

  &.ng-select-focused,
  &:hover {
    outline: 2px solid $blue4;
    outline-offset: 1px;
  }
}

ng-select.filter-clear-selection {
  .ng-select-container {
    .ng-clear-wrapper {
      display: block !important;
    }
  }
}

.selection-control,
.ng-select {
  &.filter-dropdown-selection .ng-dropdown-header {
    @include rem("padding", 5px);
    position: relative;
    width: 60%;
    border-bottom: none;

    &::before {
      display: none;
    }

    input {
      border: 1px solid $grey;
      @include rem("padding", 5px 10px);
      font-style: italic;
      color: $font-black;

      &::placeholder {
        color: $grey13;
      }
    }
  }

  &.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-header {
    background: none;
  }

  .ng-value-container {
    width: 100%;
  }

  .ng-value-label {
    font-family: "Graphik Regular";
    @include font-autoline-rem(14px);
  }

  .ng-input {
    width: 0;
    height: 0;
  }

  &.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option {
    border-bottom: none;

    @include media-breakpoint-down(sm) {
      white-space: normal;
    }
  }

  .ng-arrow-wrapper {
    @include rem("padding-right", 12px);
    padding-top: 0;
    position: absolute;
    right: 0;

    .ng-arrow {
      font-family: "smart-icon" !important;
      border: 0;
      border-color: $grey4;
      @include font-line-rem(10px, 0);
      top: auto !important;

      &::before {
        color: $light-grey2;
        content: "\e923";
      }
    }
  }

  &.ng-select-disabled>.ng-select-container {
    background-color: $grey2;
  }
}

.timepicker-select {
  &.ng-select.smart-ng-select {
    .ng-select-container {
      border: 0;
      height: 30px;
      min-height: 30px;
    }
  }

  .ng-dropdown-panel-items {
    .ng-option {
      padding: 6px !important;
    }
  }

  .ng-arrow-wrapper {
    display: none;
  }
}

.input-group {
  .ng-select-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/* Removing scroll for placeholder */
.ng-placeholder::-webkit-scrollbar-thumb {
  display: none;
}

.ng-placeholder::-webkit-scrollbar-track {
  display: none;
}

.ng-placeholder::-webkit-scrollbar {
  height: 0;
  width: 0;
}