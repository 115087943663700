$primary-blue: #08103a;
$white: #ffffff;
$black: #000000;
$blue1: #33335c;
$grey: #f0f0f0;
$grey2: #f2f2f2;
$blue2: #0072b3;
$font-black: #333333;
$grey7: #666666;
$grey3: #cccccc;
$blue: #0f72a6;
$grey4: #999999;
$grey6: #eaeaea;
$grey9: #fafafa;
$grey10: #222222;
$blue3: #0172a6;
$grey5: #586774;
$offwhite: #f7f7f7;
$light-red: #f4526b;
$light-red1: #ee1e40;
$light-red2: #9f2f05;
$light-red3: #C2515E;
$light-yellow: #ffffcc;
$black2: #000033;
$black5: #555555;
$blue4: #006796;
$grey8: #e5e5e5;
$blue5: #00bfff;
$black3: #383f61;
$black4: #111111;
$light-black: #383f61;
$blue6: #337ab7;
$blue10: #0CA3E8;
$light-grey: #e6e6e6;
$light-grey1: #c1c1c1;
$light-grey2: #929292;
$red: #dc3545;
$red1: #EF1E40;
$blue7: #3399cc;
$orange: #fe4801;
$red3: #a80007;
$dark-orange: #db8905;
$thick-orange: #ef8d0a;
$dark-orange1: #fca822;
$dark-orange2: #fcba4f;
$dark-orange3: #ffca7d;
$dark-orange4: #B48F46;
$dark-orange5: #d87923;
$shadowBlack: rgba(0, 0, 0, 0.07);
$grey11: #707070;
$grey13: #757575;
$light-blue: #DBF2FC;
$offwhite1: #F1F1F3;
$grey12: #C5C3C3;
$red4: #CC3232;
$green: #50A375;
$green-success: #3E8629;
$seablue: #15B9BF;
$blue8: #216AA9;
$green2: #339999;
$grey14: #4c4c4c;
$offwhite2: #EFEFF0;
$yellow-warning: #827800;
$purple: #993399;
$grey15: #D3D3D3;
$green3: #669999;
$green4: #339900;
$grey16: #D1D1D1;
$grey17: #454545;
$grey18: #C0C1C0;
$light-blue1: rgba(1, 114, 166, 0.04);
$purple1: #450B71;
$black6: #292929;
$black7: #091238;
$blue9: #D1E6EF;
$warning-orange: #A86800;
$success-green: #268623;
$error-red: #DE2F21;
$light-blue2: #f0f4fc;
$red5: #F01E40;
$dark-blue: #25537A;
$rose: #EE466E;
$light-blue3: #00A1CC;
$light-blue4: #F0F6F9;
$yellow: #FFE22C;