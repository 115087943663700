html,
body {
    font-family: "Graphik Regular" !important;
    color: $black;
    min-height: 100vh;

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}

body {
    position: relative;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: $black !important;
}

.profile-menu-list.mat-mdc-menu-panel {
    border-radius: 0;

    .mat-mdc-menu-content {
        @include rem("padding", 8px 16px 16px);

        .mat-mdc-menu-item {
            @include rem("min-height", 32px);

            .mat-mdc-menu-item-text {
                display: block;
                align-items: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                @include font-autoline-rem(14px);
            }
        }

        button {
            padding: 0;

            .profile-menu-icon {
                @include font-line-rem(16px);
                color: $blue3;
            }

            .icon-label {
                color: $primary-blue;
            }
        }
    }
}

.dotmenulist.mat-mdc-menu-panel {
    border-radius: 0;

    .mat-mdc-menu-content {
        min-height: 200px;
        @include rem("padding", 5px);
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        align-items: baseline;

        button {
            @include rem("padding", 0 10px);
            min-height: 95px;
            width: 80px;

            .mat-mdc-menu-item-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-self: baseline;
            }

            .smart-icon-upload {
                @include font-line-rem(33px, 50px);
                @include rem("margin-bottom", 8px);
            }

            .smart-icon-library {
                @include font-line-rem(38px, 57px);
            }

            .smart-icon-audit-trail {
                @include font-line-rem(30px, 45px);
                @include rem("margin-bottom", 12px);
            }

            .smart-icon-maintain-masters,
            .smart-icon-download,
            .smart-icon-scheduled-task,
            .smart-icon-more-action,
            .smart-icon-project {
                @include font-line-rem(30px, 45px);
                @include rem("margin-bottom", 8px);
            }

            .icon-label {
                @include font-line-rem(12px, 18px);
                font-family: "Graphik Regular";
                color: $primary-blue;
            }
        }

        button:hover {
            background-color: $grey;
            @include rem("border-radius", 2px);
        }
    }
}

.mat-mdc-menu-panel {
    .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
        letter-spacing: normal;
    }

    &.profile-menu-list,
    &.dotmenulist {
        background: transparent;
        margin-top: -10px;

        .mat-mdc-menu-content {
            background: $white;
            margin-top: 10px;

            &::before {
                content: '';
                position: absolute;
                right: 1rem;
                top: 1px;
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 10px solid $white;
            }
        }

        &.no-arrow {
            .mat-mdc-menu-content {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.toggleBtnMenu.mat-mdc-menu-panel {
    @include rem("border-radius", 3px);

    .mat-mdc-menu-content {
        button.tab-name {
            @include font-line-rem(14px, 21px);
            color: $primary-blue;
        }

        button:hover {
            background-color: $grey;
        }
    }
}

.header-search-dropdown-options.mat-mdc-autocomplete-panel {

    mat-option {
        border-bottom: 1px solid $grey2;

        .mdc-list-item__primary-text {
            .dropdown-options {
                a {
                    text-decoration: none;
                    @include font-line-rem(16px, 24px);
                    color: $blue2;
                }

                a:hover {
                    text-decoration: underline;
                }

                p {
                    @include font-line-rem(12px, 18px);
                    color: $font-black;
                }
            }
        }
    }
}

.smart-container {
    padding: 0 3%;
}

.page-header-container {
    background: $grey;

    .header-section {
        h1 {
            color: $primary-blue;
            @include font-line-rem(36px, 54px);
            font-family: "Graphik Semibold";

            @media screen and (max-width: 576px) {
                @include font-autoline-rem(25px);
            }
        }
    }
}

.smart-form-control {
    @include rem("border-radius", 2px);
    border: 1px solid $grey3;
    @include font-line-rem(12px, 18px);
    color: $font-black;
    @include rem("padding", 6px 10px);

    &:hover,
    &:focus {
        outline: 2px solid $blue4;
        outline-offset: 1px;
        box-shadow: none;
    }
}

.smart-btn {

    &:hover,
    &:focus {
        outline: 2px solid $blue4 !important;
        outline-offset: 2px;
    }

    &.btn-icon {
        @include font-autoline-rem(16px);
        color: $blue3;
    }

    &:disabled {
        border: 0;
    }

    &.btn-danger {
        background-color: $error-red;
        color: $white;
    }

    &.btn-warning {
        background-color: $warning-orange;
        color: $white;
    }
}

.smart-primary-btn {
    color: $white;
    @include font-line-rem(14px, 21px);
    background: $blue3;
    border-radius: 3px;
    @include rem("padding", 6px 16px);

    &:disabled,
    &:hover,
    &:focus {
        color: $white;
        background: $blue3;
        border-radius: 3px;
    }

    &:disabled {
        opacity: 50%;
    }
}

.btn-outline-primary {
    color: $blue3;
    @include font-line-rem(14px, 21px);
    background: $white;
    border-radius: 3px;
    @include rem("padding", 12px 15px);

    &:disabled,
    &:hover,
    &:focus {
        color: $white;
        background: $blue3 !important;
        border-radius: 3px;
    }

    &:disabled {
        opacity: 50%;
    }
}

.flex-1 {
    flex: 1;
}

a {
    color: $blue3;

    &:hover,
    &:focus {
        outline: 2px solid $blue4;
        outline-offset: 0;
    }
}

.page-header-section {
    padding: 0 3%;
    background: $grey;

    .page-header-name {
        color: $font-black;
        @include font-line-rem(36px, 54px);
        font-family: "Graphik Semibold";
        @include rem("margin", 0 0 30px);
    }

    .page-sub-header {
        color: $grey7;
        @include font-line-rem(12px, 18px);
    }
}

.page-tab-view {
    background-color: $grey;

    .field-section {
        @include rem("margin-bottom", 30px);

        .field-label {
            color: $grey7;
            @include font-line-rem(12px, 18px);

            .smart-icon-moreInfo {
                color: $blue3;
            }
        }
    }

    .checkbox-label-container {
        .show-six-label {
            color: $grey7;
            @include font-line-rem(12px, 18px);
        }
    }

    mat-tab-group {

        mat-tab-header {
            padding: 0 3%;
            margin-left: -12px;
            border: none;

            .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                    .mat-mdc-tab.mdc-tab {
                        opacity: 1;
                        background-color: $grey;
                        height: 36px;
                        @include rem("padding-left", 10px);
                        @include rem("padding-right", 10px);
                        min-width: 80px;
                        border: 2px solid transparent;

                        .mdc-tab__text-label {
                            font-family: "Graphik Regular";
                            @include font-line-rem(14px, 21px);
                            color: $font-black !important;
                            letter-spacing: normal;
                            text-transform: uppercase;
                        }

                        &.mdc-tab-indicator--active {
                            background-color: $white;

                            .mdc-tab__text-label {
                                font-family: "Graphik Medium";
                            }
                        }

                        &:not(:last-child) {
                            @include rem("margin-right", 10px);
                        }

                        .mdc-tab__text-label {
                            overflow: auto;
                        }

                        &.cdk-keyboard-focused,
                        &:hover,
                        &:focus {
                            border: 2px solid $blue4;
                        }

                        .mdc-tab-indicator .mdc-tab-indicator__content {
                            display: none;
                        }
                    }
                }
            }
        }

        .mat-mdc-tab-body-wrapper {
            mat-tab-body {
                height: 100%;
                background: $white;

                .mat-mdc-tab-body-content {
                    @include rem("margin-top", 32px);

                    .demand-details,
                    .project-details,
                    .resource-details,
                    .sourcing-details,
                    .userAccess-details {
                        max-width: 800px;
                    }

                    .tab-content-header {
                        color: $primary-blue;
                        @include font-line-rem(16px, 24px);
                        @include rem("margin-bottom", 20px);
                    }
                }
            }
        }

        &.smart-grouped-tab {
            .mat-mdc-tab-body .mat-mdc-tab-body-content {
                margin-top: 0;
            }

            .mat-mdc-tab-header {
                padding: 0;
                margin: 0;
            }

            .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                    .mat-mdc-tab.mdc-tab {
                        background-color: transparent;
                        position: relative;
                        @include rem("height", 50px);
                        border: 0;

                        &.mdc-tab-indicator--active {
                            .indicator-tab {
                                &::after {
                                    content: '';
                                    position: absolute;
                                    background-color: $font-black;
                                    left: -10px;
                                    bottom: 0;
                                    height: 5px;
                                    right: -9px;
                                    z-index: -1;
                                }
                            }
                        }

                        .indicator-tab {
                            @include rem("margin-bottom", 10px);

                            &::before {
                                content: '';
                                position: absolute;
                                background-color: $font-black;
                                left: -10px;
                                top: 0;
                                width: 10px;
                                @include rem("height", 36px);
                                z-index: -1;
                            }

                            &.red {

                                &::before,
                                &::after {
                                    background-color: $red1;
                                }
                            }

                            &.green {

                                &::before,
                                &::after {
                                    background-color: $green;
                                }
                            }

                            &.darkblue {

                                &::before,
                                &::after {
                                    background-color: $primary-blue;
                                }
                            }

                            &.lightblue {

                                &::before,
                                &::after {
                                    background-color: $blue;
                                }
                            }

                            &.seablue {

                                &::before,
                                &::after {
                                    background-color: $seablue;
                                }
                            }

                            &.blue {

                                &::before,
                                &::after {
                                    background-color: $blue8;
                                }
                            }

                            &.orange {

                                &::before,
                                &::after {
                                    background-color: $dark-orange4;
                                }
                            }

                            &.green2 {

                                &::before,
                                &::after {
                                    background-color: $green2;
                                }
                            }

                            &.purple {

                                &::before,
                                &::after {
                                    background-color: $purple;
                                }
                            }

                            &.green3 {

                                &::before,
                                &::after {
                                    background-color: $green3;
                                }
                            }

                            &.red5 {

                                &::before,
                                &::after {
                                    background-color: $red5;
                                }
                            }

                            .text-label {
                                color: $primary-blue;
                                text-transform: capitalize;
                                @include rem("margin-left", 10px);
                            }
                        }

                        &:hover {
                            border: 0;
                            outline: 2px solid $blue4;
                            outline-offset: -2px;
                        }
                    }
                }
            }


            &.rejection-refusal-tab {
                mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab {
                    border: 0;
                }

                .mat-mdc-tab-header-pagination-chevron {
                    border-color: $black !important;
                }
            }
        }

        &.sr-tab {
            .mat-mdc-tab-labels {
                .mat-mdc-tab:last-child {
                    display: none;
                }
            }
        }
    }
}

.form-field-container {
    .field-label {
        color: $grey7;
        @include font-line-rem(12px, 18px);
    }

    &.input-field {
        input::-webkit-input-placeholder {
            /* Edge */
            color: $grey7;
            @include font-line-rem(14px, 21px);
        }

        input::-ms-input-placeholder {
            /* Internet Explorer */
            color: $grey7;
            @include font-line-rem(14px, 21px);
        }

        input::placeholder {
            color: $grey7;
            @include font-line-rem(14px, 21px);
        }
    }
}

@media screen and (max-height: 320px) {
    .cdk-global-scrollblock {
        position: static;
    }

    .mat-datepicker-popup {
        top: 0px !important;
        left: 0px !important;
    }

    .mat-datepicker-content {
        overflow: auto;
    }

}

.date-picker-container {
    .smart-icon-calendar {
        color: $grey7;
        @include font-line-rem(13px, 19px);
        top: 9px;
        right: 22px;
        cursor: pointer;
    }

    .smart-icon-erase {
        color: $blue4;
        @include font-line-rem(14px, 0);
        right: -28px;
        top: 10px;
    }

    input::-webkit-input-placeholder {
        /* Edge */
        color: $grey7;
        @include font-line-rem(14px, 21px);
    }

    input::-ms-input-placeholder {
        /* Internet Explorer */
        color: $grey7;
        @include font-line-rem(14px, 21px);
    }

    input::placeholder {
        color: $grey7;
        @include font-line-rem(14px, 21px);
    }
}

.checkbox-container {
    position: relative;
    @include rem("padding-left", 35px);
    @include rem("margin-bottom", 12px);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:focus~.checkmark {
            outline: 2px solid $blue4;
            outline-offset: 1px;
        }
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border: 1px solid $grey7;
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 0px;
            width: 6px;
            height: 12px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    /* On mouse-over, add a grey background color */
    &:hover input~.checkmark {
        outline: 2px solid $blue4;
        outline-offset: 1px;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked~.checkmark {
        background-color: $blue;
        border: 1px solid $grey7;
    }

    /* Show the checkmark when checked */
    input:checked~.checkmark:after {
        display: block;
    }
}

.audit-trail-page {
    .footer-form-action-btn {
        .primary-btn {
            color: $white;
            @include font-line-rem(14px, 21px);
            background: $blue3;
            border-radius: 3px;
            @include rem("padding", 12px 15px);

            &:disabled {
                opacity: 50%;
            }
        }

        .secondary-btn {
            color: $blue3;
            @include font-line-rem(14px, 21px);
        }
    }
}

.mat-mdc-radio-group,
.mat-checkbox-group {

    .mat-mdc-radio-button:not(:last-child),
    .mat-mdc-checkbox:not(:last-child) {
        @include rem("padding-right", 32px);
    }
}

.mat-mdc-radio-button {
    .mdc-radio {
        width: 12px;
        height: 12px;
        padding: calc((var(--mdc-radio-state-layer-size) - 12px) / 2) !important;
        margin: -14px -7px -7px -14px;

        .mdc-radio__background {
            width: 12px;
            height: 12px;

            &::before {
                background-color: $blue3;
                top: calc(-1 * (var(--mdc-radio-state-layer-size) - 12px) / 2) !important;
                left: calc(-1 * (var(--mdc-radio-state-layer-size) - 12px) / 2) !important;
            }
        }

        .mdc-radio__inner-circle {
            border-width: 6px;
        }

        .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
            border-color: $blue3 !important;
        }

        .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
            border-color: $blue3 !important;
        }

        .mdc-radio__native-control:enabled:checked+.mdc-radio__background {
            .mdc-radio__outer-circle {
                border-color: $blue3 !important;
            }

            .mdc-radio__inner-circle {
                border-color: $blue3 !important;
                background-color: $blue3;
            }
        }
    }

    &.mat-mdc-radio-checked {
        .mdc-radio__background {
            &::before {
                background-color: $blue3 !important;
            }
        }

        .mat-ripple-element {
            background-color: $blue3 !important;
        }
    }

    .mdc-form-field {
        color: $font-black;
        @include font-autoline-rem(14px);
        letter-spacing: normal
    }
}

.mat-mdc-checkbox {
    .mdc-form-field {
        color: $font-black;
        @include font-autoline-rem(14px);
        letter-spacing: normal;
    }

    .mdc-checkbox {
        padding: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        margin: -12px;
        margin-right: -8px;

        .mdc-checkbox__background {
            width: 16px;
            height: 16px;
            top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
            left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);

            svg {
                padding: 1px;
            }
        }

        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
            border-color: $blue2 !important;
            background-color: $white !important;
        }

        .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
            border-color: $grey12;
            background-color: $white;
        }

        .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
            background-color: $blue2 !important;
            border-color: $blue2 !important;
        }

        .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
            background-color: $blue2 !important;
        }
    }
}

.audit-trail-page,
.smart-table {
    .mat-mdc-table {
        border-collapse: separate;

        thead {
            tr {
                background: $grey5;

                th {
                    border-right: 1px solid $white;
                    color: $white;
                    @include font-line-rem(12px, 18px);
                    font-family: "Graphik Medium";
                    @include rem("padding", 22px 15px);
                    white-space: nowrap;

                    .mat-sort-header-arrow {
                        color: $white;
                        transform: translateY(0) !important;
                    }

                    &.text-center .mat-sort-header-container {
                        justify-content: center;
                    }

                    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                        border-color: $white !important;
                    }

                    .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
                        background-color: $white !important;
                        border-color: $white !important;
                        opacity: 0.75;

                        .mdc-checkbox__checkmark {
                            color: $blue3 !important;
                        }
                    }

                    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                        background-color: $grey6 !important;
                        border-color: $grey6 !important;
                    }

                    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
                        color: $blue3 !important;
                    }

                    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
                        background-color: $white !important;
                        border-color: $white !important;
                    }

                    &.mat-mdc-table-sticky {
                        @include rem("padding", 22px 30px);

                        @include media-breakpoint-down(md) {
                            position: static !important;
                        }
                    }

                }
            }
        }

        tbody {
            tr {
                background: $white;

                &.expand-expanded-row {
                    box-shadow: inset 0 0 2px rgb(0 0 0 / 50%);
                    background-color: #f3fafd !important;
                }

                td {
                    @include rem("padding", 16px 15px);
                    border: none;
                    color: $font-black;
                    @include font-line-rem(14px, 21px);
                    max-width: 210px;
                    font-family: "Graphik Regular";

                    .inner-text {
                        @include font-autoline-rem(12px);
                    }

                    .smart-icon-trash {
                        @include font-line-rem(16px, 24px);
                        color: $blue3;
                    }

                    .trash-disable {
                        @include font-line-rem(16px, 24px);
                        border-color: transparent;
                        color: $grey7;
                    }

                    a.download-link {
                        color: $blue3;
                        overflow: auto;
                    }

                    .form-control {
                        @include rem("padding", 4px 12px);
                    }

                    .expand-element-detail {
                        &.expanded {
                            box-shadow: inset 0 0px 2px rgb(0 0 0 / 50%);

                            .detail-cell {
                                .title {
                                    color: $grey7;
                                    @include font-autoline-rem(12px);
                                }

                                .value {
                                    color: $font-black;
                                    @include font-autoline-rem(14px);
                                }
                            }
                        }
                    }

                    &.grid-filter-message {
                        @include font-autoline-rem(18px);
                    }

                    &.talent-grid-filter-message {
                        @include font-autoline-rem(18px);
                        color: $grey7;
                    }

                    &.mat-mdc-table-sticky {

                        @include media-breakpoint-down(md) {
                            position: static !important;
                        }
                    }


                }

                &:nth-child(odd) {
                    background: $grey9;

                    &.expand-element-row {
                        background: #fff !important;
                    }

                    td &.mat-mdc-table-sticky {
                        background-color: $grey9;
                    }

                }

                &.expand-element-row:nth-child(4n+1) {
                    background-color: $grey9 !important;
                }

                .active {
                    background-color: $light-blue;

                    &.form-check-input[type="radio"]:checked {
                        background-color: $blue;
                    }
                }
            }
        }
    }

    &.inline-editable tr td {
        vertical-align: top;
        max-width: fit-content !important;
    }
}

.indicator-table {
    padding-top: 3px;

    &::before {
        position: absolute;
        content: '';
        left: 0;
        height: 3px;
        right: 1px;
        background-color: $font-black;
        z-index: 2;
        top: 0;
    }

    &.red {
        &::before {
            background-color: $red1;
        }
    }

    &.green {
        &::before {
            background-color: $green;
        }
    }

    &.blue {
        &::before {
            background-color: $primary-blue;
        }
    }

    &.seablue {
        &::before {
            background-color: $seablue;
        }
    }

    &.blue {

        &::before,
        &::after {
            background-color: $blue8;
        }
    }

    &.orange {
        &::before {
            background-color: $dark-orange4;
        }
    }

    &.green2 {

        &::before,
        &::after {
            background-color: $green2;
        }
    }

    &.purple {
        &::before {
            background-color: $purple;
        }
    }

    &.green3 {
        &::before {
            background-color: $green3;
        }
    }

    &.red5 {
        &::before {
            background-color: $red5;
        }
    }
}

mat-tooltip-component {
    .mdc-tooltip__surface {
        letter-spacing: normal !important;
    }

    .offwhite-tooltip {
        .mdc-tooltip__surface {
            @include font-autoline-rem(12px);
            color: $grey7;
            background-color: $offwhite;
            border: 1px solid $grey3;
            @include rem("padding", 6px);
            max-width: 355px;
            white-space: pre-line;
            max-height: max-content;

            @include media-breakpoint-down(md) {
                max-height: 200px;
                overflow: auto;
            }
        }

        &.mandatory-tooltip {
            .mdc-tooltip__surface {
                color: $red4;
            }
        }
    }

    .profile-tooltip {
        .mdc-tooltip__surface {
            font-family: "Graphik Regular";
            @include font-autoline-rem(14px);
            @include rem("padding", 8px 16px);
            color: $black;
            border-radius: 2px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            background-color: $white;
            margin: 0;
            max-width: fit-content;
        }
    }
}

.smart-btn-link {
    @include font-line-rem(14px, 21px);
    color: $blue3;

    .smart-btn-icon {
        @include font-line-rem(12px, 18px);
    }
}

.table-action-btn {
    text-decoration: none;
    @include font-line-rem(20px, 20px);
}

.smart-slide {
    position: relative;

    .mdc-switch {
        --mdc-switch-track-width: 40px;
        --mdc-switch-handle-width: 16px;
        --mdc-switch-selected-icon-size: 14px;
        --mdc-switch-track-height: 20px;
        --mdc-switch-track-shape: 10px;
        --mdc-switch-handle-height: 16px;
        --mdc-switch-unselected-icon-size: 14px;
        --mdc-switch-disabled-handle-opacity: 0.5;
        --mdc-switch-disabled-selected-icon-opacity: 0.5;
        --mdc-switch-disabled-track-opacity: 0.5;

        &:enabled,
        &:disabled {
            .mdc-switch__track {
                &::before {
                    background-color: transparent !important;
                    border: 1px solid $grey7;
                }

                &::after {
                    background-color: transparent !important;
                    border: 1px solid $blue6;
                }
            }
        }

        &.mdc-switch--unselected {
            .mdc-switch__handle {
                left: 2px;
            }

            &:enabled,
            &:disabled {
                .mdc-switch__icon {
                    fill: transparent;
                }

                .mdc-switch__handle {
                    &::after {
                        background: $grey7 !important;
                    }
                }
            }
        }

        &.mdc-switch--selected {
            .mdc-switch__handle {
                left: -2px;
            }

            &:enabled,
            &:disabled {
                .mdc-switch__handle {
                    &::after {
                        background: $blue6 !important;
                    }
                }
            }
        }

        &:enabled:focus .mdc-switch__ripple::before,
        &:enabled:focus .mdc-switch__ripple::after {
            background: $blue6 !important;
        }
    }

    &.cdk-keyboard-focused,
    &:hover {
        .mdc-switch__handle {
            outline: 2px solid $blue4;
            outline-offset: 1px;
        }
    }

    .mdc-form-field--align-end>label {
        margin-right: 10px;
    }
}

.mat-sort-header {
    &.cdk-keyboard-focused {
        .mat-sort-header-container {
            outline: 2px solid $white;
            outline-offset: 1px;
            border: 0 !important;
        }
    }

    .mat-sort-header-arrow {
        opacity: 1 !important;
        font-family: "smart-icon" !important;

        div {
            display: none;
        }

        &::before {
            position: relative;
            top: -3px;
        }
    }

    &[aria-sort="none"] {
        .mat-sort-header-arrow {
            &::before {
                content: "\e91e";
            }
        }
    }

    &[aria-sort="ascending"] {
        .mat-sort-header-arrow {
            &::before {
                content: "\e91d";
                font-size: 6px;
                transform: rotate(180deg);
                top: 0;
            }
        }
    }

    &[aria-sort="descending"] {
        .mat-sort-header-arrow {
            &::before {
                content: "\e91d";
                font-size: 6px;
            }
        }
    }
}

.confirm-popup {
    position: relative !important;

    .close-btn {
        top: 12px;
        right: 6px;
        color: $grey7;
        z-index: 1;
    }

    .mat-mdc-dialog-container {
        .mat-mdc-dialog-surface {
            @include media-breakpoint-up(sm) {
                @include rem("padding", 44px 56px);
            }

            .mdc-dialog__title {
                @include rem("padding", 0px);
                color: $primary-blue !important;
                font-family: "Graphik Semibold" !important;
                @include font-line-rem(24px, 36px);

                &::before {
                    display: none;
                }
            }

            .mdc-dialog__content {
                @include rem("padding", 0px);
                @include font-autoline-rem(16px);
                letter-spacing: normal;
                color: $font-black;
            }

            .mdc-dialog__actions {
                @include rem("padding", 0px);
            }
        }


        @include media-breakpoint-down(sm) {
            max-height: 53vh;
        }
    }

    &.widthchange {
        .mat-mdc-dialog-container {
            padding: 30px 30px 50px;
        }
    }

    &.uncheckPopup {
        .mat-mdc-dialog-container {
            .mat-mdc-dialog-content {
                .snowLink {
                    text-decoration: none;
                }
            }

            .smart-icon-close {
                display: none;
            }
        }
    }
}

.smart-label {
    @include font-line-rem(12px, 18px);
    color: $grey7;
}

/*-----------------------------------*/

.donut-tooltip {
    width: 10px;
    height: 10px;
    background: $dark-orange;
    margin-right: 10px;
    float: left;
    margin-top: 6px;
    margin-left: 6px;
}

.donut-tooltip.tagged {
    background: $dark-orange;
}

.donut-tooltip.active {
    background: $thick-orange;
}

.donut-tooltip.refusal {
    background: $dark-orange1;
}

.donut-tooltip.rejected {
    background: $dark-orange2;
}

.donut-tooltip.ijp {
    background: $dark-orange3;
}

/*-----------------------------------*/

.mat-mdc-menu-panel.table-filter-menu {
    border-radius: 0px;

    .mat-mdc-menu-content {
        padding: 12px 16px;
        min-width: 140px;

        .mat-mdc-menu-item:hover {
            background-color: $white;
        }

        .mat-mdc-menu-item {
            height: auto;
            padding: 0;
            line-height: 1.5;
        }

        button {
            color: $white;
            background-color: $blue3;
            border-radius: 2px;
            border: 0;
            padding: 0px 20px;
            @include font-autoline-rem(14px);
        }
    }
}

.filter-style {
    color: $white;
}

.toaster-style {
    max-width: none !important;
}

.mat-mdc-snack-bar-container {
    padding: 24px;

    .mdc-snackbar__surface {
        @include rem("padding", 10px 16px);
        box-shadow: none;
        @include rem("min-height", 40px);

        @include media-breakpoint-down(md) {
            min-width: 250px !important;
        }

        .mdc-snackbar__label {
            padding: 0;
        }

        .customised-toast {
            position: relative;
            padding-right: 2rem;

            .mdc-snackbar__label {
                @include font-autoline-rem(14px);
                color: $black;
            }

            .btn-undo {
                display: none;
                background-color: rgba(255, 255, 255, 0.3);
                border: none;
                border-radius: 4px;
                margin-left: 8px;
            }

            .close-toast {
                padding: 2px;
                right: 0;
                top: 0;
            }
        }
    }

    &.success {
        .mdc-snackbar__surface {
            background-color: $green-success;

            .customised-toast {
                .mdc-snackbar__label {
                    color: $white;
                }

                .close-toast {
                    color: $white;
                }
            }
        }
    }

    &.warning {
        .mdc-snackbar__surface {
            background-color: #ffcc66;
        }
    }

    &.danger {
        .mdc-snackbar__surface {
            background-color: $red4;

            .customised-toast {
                .mdc-snackbar__label {
                    color: $white;
                }
            }

            .close-toast {
                color: $white;
            }
        }
    }
}

.statusColorStyles {
    color: $white;

    &.pendingColor {
        background-color: $red;
    }

    &.approvedColor {
        background-color: #3e8629;
    }

    &.rejectedColor {
        background-color: #343a40;
    }

    &.deletedColor {
        background-color: #ffc107;
        color: $font-black;
    }
}

.resource-detail-tab-view {
    background-color: $black2;

    mat-tab-group.profile-tab {

        &>mat-tab-header {
            padding: 0 5%;
            padding-right: 80px;

            @include media-breakpoint-up(md) {
                padding-right: 160px;
            }

            .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                    .mat-mdc-tab-labels {
                        padding-right: 80px;

                        @include media-breakpoint-up(md) {
                            padding-right: 160px;
                        }

                        .mat-mdc-tab.mdc-tab {
                            opacity: 1;
                            @include rem("padding-left", 20px);
                            @include rem("padding-right", 20px);
                            min-width: 80px;
                            border: 2px solid transparent;

                            .mdc-tab__text-label {
                                color: $white !important;
                                @include font-autoline-rem(16px);
                                text-transform: uppercase;
                                letter-spacing: normal;
                            }

                            &.mdc-tab-indicator--active {
                                background-color: $white;

                                .mdc-tab__text-label {
                                    font-family: "Graphik Medium";
                                    color: $primary-blue !important;
                                }
                            }

                            .mdc-tab__text-label {
                                overflow: auto;
                            }

                            &.cdk-keyboard-focused,
                            &:hover,
                            &:focus {
                                border: 2px solid $blue4;
                            }

                            .mdc-tab-indicator .mdc-tab-indicator__content {
                                display: none;
                            }
                        }
                    }
                }
            }

            border: none;
        }

        .mat-mdc-tab-body-wrapper {
            mat-tab-body {
                height: 100%;
                background: $white;
            }
        }

        .mat-mdc-tab-header-pagination-disabled {
            .mat-mdc-tab-header-pagination-chevron {
                border-color: $grey4;
            }
        }

        .mat-mdc-tab-header-pagination-chevron {
            border-color: $white;
        }
    }

    mat-tab-group.learning-tab {

        mat-tab-header {
            border: 0;

            @include media-breakpoint-up(sm) {
                padding-right: 0;
                padding-left: 0;
            }

            .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                    .mat-mdc-tab-labels {
                        border-bottom: 1px solid $grey6;
                        margin-bottom: 15px;
                    }

                    .mat-mdc-tab.mdc-tab {
                        opacity: 1;
                        min-width: auto;
                        height: auto;
                        @include rem("padding", 5px 0);
                        margin-right: 30px;
                        border: 2px solid transparent;

                        .mdc-tab__text-label {
                            color: $primary-blue !important;
                            @include font-autoline-rem(14px);
                            text-transform: capitalize;
                            letter-spacing: normal;
                        }

                        &.mdc-tab-indicator--active {
                            background: $white;
                            border-bottom: 2px solid $primary-blue;

                            .mdc-tab__text-label {
                                color: $primary-blue !important;
                                font-family: "Graphik Medium";
                            }
                        }

                        .mdc-tab__text-label {
                            overflow: auto;
                        }

                        &.cdk-keyboard-focused,
                        &:hover,
                        &:focus {
                            border: 2px solid $blue4;
                        }

                        .mdc-tab-indicator .mdc-tab-indicator__content {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .mat-mdc-tab-body-wrapper {
        mat-tab-body {
            height: 100%;
            background: $white;
        }
    }

    .mat-mdc-tab-header-pagination-disabled {
        .mat-mdc-tab-header-pagination-chevron {
            border-color: $grey4;
        }
    }

    .mat-mdc-tab-header-pagination-chevron {
        border-color: $white;
    }
}


mat-tab-group.traning-tab {

    mat-tab-header {
        .mat-mdc-tab-label-container {
            .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                    border-bottom: 0;

                    .mat-mdc-tab.mdc-tab {

                        @include rem("padding", 5px 10px);
                        margin-right: 0;
                        border: 0;
                        border-top: 1px solid $font-black;
                        border-bottom: 1px solid $font-black;

                        .mdc-tab__text-label {
                            color: $grey7 !important;
                            @include font-autoline-rem(14px);
                            text-transform: capitalize;
                            letter-spacing: normal;
                        }

                        &.mdc-tab-indicator--active {
                            background: $font-black;
                            border-bottom: 2px solid $primary-blue;
                            border-bottom: 0;

                            .mdc-tab__text-label {
                                color: $white !important;
                            }
                        }

                        &:first-child {
                            border-top-left-radius: 6px;
                            border-bottom-left-radius: 6px;
                            border-left: 1px solid $font-black;
                        }

                        &:last-child {
                            border-top-right-radius: 6px;
                            border-bottom-right-radius: 6px;
                            border-right: 1px solid $font-black;
                        }
                    }
                }
            }
        }
    }
}

.comments-option-section {
    top: -12px;
    right: 5%;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        top: 0px;
    }

    .comments-icon {
        font-size: 20px;
        color: $blue6;

        @include media-breakpoint-up(md) {
            font-size: 30px;
        }
    }

    .more-btn {
        font-size: 20px;
        color: $white;
        height: 36px;

        @include media-breakpoint-up(md) {
            font-size: 30px;
        }
    }

    .mat-mdc-menu-content {
        min-width: 160px;
    }
}


.mandatory-field {
    color: $red4;
    @include font-autoline-rem(14px);
}

.form-label {
    @include font-autoline-rem(12px);
    color: $grey7;
}

/*------- Custom scroll css ---------*/
.custom-overflow-scroll::-webkit-scrollbar-thumb {
    background-color: $primary-blue;
    border-radius: 4px;
}

.custom-overflow-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 13px $grey3;
}

.custom-overflow-scroll::-webkit-scrollbar,
.access-modal .mat-mdc-dialog-container::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

/*-----------------------------------*/
.form-control {
    color: $font-black;
    border-radius: 2px;
    border-color: $light-grey2;
    @include font-autoline-rem(14px);

    &.error {
        border-color: $red;
    }

    &:disabled {
        background-color: $grey2;
        color: $grey7;
        border-color: $light-grey2;
    }

    &::placeholder {
        color: $grey13 !important;
    }

    &:-ms-input-placeholder {
        color: $grey13 ;
    }

    &::-ms-input-placeholder {
        color: $grey13 ;
    }
}


.resource-details-tab-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

.tooltip {
    position: absolute;
    height: auto;
    padding: 5px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.8) !important;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.custom-mat-accordion {
    .mat-expansion-panel-spacing {
        margin: 0;
    }

    .mat-expansion-panel {
        border-radius: 0 !important;
        box-shadow: none !important;

        .mat-expanded {
            &.mat-expansion-panel-header {
                .title {
                    .expand-icon {
                        &::before {
                            content: "\e927";
                        }
                    }
                }
            }
        }

        .mat-expansion-panel-header {
            height: auto;
            padding: 0;

            &.cdk-focused {
                border: 1px solid $black;
            }

            .mat-expansion-panel-header-title {
                margin-right: 0;
            }

            &.mat-expanded {
                height: auto;
            }

            .title {
                font-family: "Graphik Medium";
                background-color: $grey9;
                padding: 8px 24px;

                .expand-icon {
                    font-size: 14px;
                    color: $blue3;
                    font-family: "smart-icon" !important;

                    &::before {
                        content: "\e910";
                    }
                }
            }

            .mat-expansion-indicator {
                display: none;
            }
        }

        .mat-expansion-panel-content {
            .mat-expansion-panel-body {
                padding: 0;
            }
        }
    }

    .sub-custom-mat-accordion {
        .mat-expansion-panel {
            .mat-expanded {
                &.mat-expansion-panel-header {
                    .sub-title {
                        .expand-icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .mat-expansion-panel-header {
                .sub-title {
                    font-family: "Graphik Regular";
                    @include font-autoline-rem(14px);
                    background-color: $grey9;
                    padding: 4px 24px;

                    .expand-icon {
                        color: $blue3;
                        font-family: "smart-icon" !important;
                        transform: rotate(90deg);

                        &::before {
                            content: "\e928";
                        }
                    }
                }
            }

            .mat-expansion-panel-content {
                .mat-expansion-panel-body {
                    padding: 0;
                    font-family: "Graphik Regular";

                    .inner-panel-body {
                        padding: 8px 24px;

                        .checkbox-container {
                            padding-left: 22px;

                            .checkmark {
                                height: 12px;
                                width: 12px;
                                top: 3px;

                                &:after {
                                    left: 3px;
                                    top: 0;
                                    width: 4px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                }
                            }
                        }

                        .show-more {
                            @include font-autoline-rem(12px);
                            color: $blue3;
                        }
                    }
                }
            }
        }
    }
}

.search-mat-accordion {
    .mat-expansion-panel-spacing {
        margin: 0;
    }

    .mat-expansion-panel {
        border-radius: 0 !important;
        box-shadow: 0px 2px 5px -3px #000 !important;

        .mat-expansion-panel-body {
            padding: 0;
        }
    }
}

.text-red,
.link-danger {
    color: $red4 !important;
}

.link-warning {
    color: $yellow-warning !important;
}

.footer-band {
    position: fixed;
    z-index: 99999;
    width: 100%;
    bottom: 0;
    left: 0;
    background: $white;

    max-height: 0;
    padding: 0 50px;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    border: 1px solid $offwhite1;

    &.isOpen {
        max-height: 150px;
        padding: 16px;
        opacity: 1;
        box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.07);

        @include media-breakpoint-up(sm) {
            padding: 14px 50px;
        }
    }
}

.chip-container {
    margin-top: 22px;

    .chip {
        display: inline-block;
        @include font-line-rem(12px);
        background-color: $grey2;
        color: $black4;
        padding: 2px 4px;
    }
}

.closebtn {
    color: $light-red1;
    font-weight: 200;
    float: right;
    font-size: 7px;
    cursor: pointer;

    &:hover {
        outline: 2px solid $blue7;
        outline-offset: 1px;
    }
}

.select-option-wrap {
    &.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        .text-truncate {
            white-space: normal;
            text-overflow: unset;
            overflow: unset;
        }

        height: auto;
    }

    &.selection-control .ng-value-label {
        overflow: hidden;
    }
}

.filter-card-container {
    position: fixed;
    background-color: $white;
    top: 0;
    right: 0;
    z-index: 101;
    width: 267px;
    border: 1px solid $offwhite1;
    border-radius: 0;
    box-shadow: 0px 1px 2px $shadowBlack;

    .closebtn {
        color: $blue7;
        @include font-line-rem(14px);
        cursor: pointer;

        &:hover {
            outline: 2px solid $blue7;
            outline-offset: 1px;
        }
    }

    .filter-card-header {
        @include font-line-rem(24px);
        font-family: "Graphik Medium";
        padding-left: 25px;
        padding-bottom: 24px;
        padding-top: 24px;
        background-color: $white;
        border: 1px solid $offwhite1;
    }

    .filter-card-body {
        overflow-y: auto;
        height: 80vh;

        .search-container {
            padding: 12px 24px;

            .form-control {
                padding: 3px 12px;
            }
        }

        .filter-date-icon {
            top: 46px;
            right: 35px;
        }

    }

    .filter-card-footer {
        background-color: $white;
        border-top: 1px solid $offwhite1;
    }
}

.resource-mangement-filter {
    .filter-card-container .filter-card-body {
        height: calc(100vh - 188px);
    }
}

.tags-btn {
    color: $light-red;
    font-family: "Graphik Medium";
    text-transform: uppercase;
}

.tags-list {
    margin-top: 6px;

    &.tags-list-added {
        span {
            color: $grey9;
            background-color: $black3;
        }
    }

    span {
        padding: 5px;
        color: $black4;
        @include font-autoline-rem(12px);
        background-color: $light-black;
        margin-right: 6px;
        margin-bottom: 6px;

        .btn-link {
            @include font-line-rem(8px);
            color: $light-red;
        }
    }
}

.mat-mdc-paginator {
    color: $grey7 !important;
    letter-spacing: normal !important;

    .mat-mdc-icon-button {
        background-color: $blue3;
        color: $white;
        @include rem("width", 24px);
        @include rem("height", 24px);
        @include rem("line-height", 24px);
        padding: 0;

        &:not(:last-child) {
            @include rem("margin-right", 24px);
        }

        .mat-mdc-button-touch-target {
            @include rem("width", 24px);
            @include rem("height", 24px);
        }
    }

    .mat-mdc-paginator-icon {
        @include rem("width", 20px);
        fill: $white;
    }

    .mat-mdc-icon-button[disabled] {
        background-color: $grey6;

        .mat-mdc-paginator-icon {
            fill: $grey3;
        }
    }
}


.error-validation-msg {
    font-size: 12px;
    color: $red4;
}

.details-text {
    h2 {
        font-family: 'Graphik Regular';
        @include font-autoline-rem(16px);
        color: $red;
    }

    .details-sub-text {
        color: $grey14;
        @include rem('padding-bottom', 30px);
    }
}

.rotate-180 {
    transform: rotate(180deg);
}

.sticky-row {
    th {
        &.mat-mdc-table-sticky {
            outline: 1px solid $white;
            border-right: 0px;
        }
    }
}

.rotate-90 {
    transform: rotate(-90deg);
}

.basic-details-tab {
    mat-tab-group {

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        flex-direction: column;

        mat-tab-header {
            .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                    .mat-mdc-tab-labels {

                        @include media-breakpoint-up(md) {
                            flex-direction: column;
                        }

                        flex-direction: row;
                    }

                    .mat-mdc-tab {
                        position: relative;
                        padding: 0 8px 0 12px;
                        min-width: 236px;

                        @include media-breakpoint-up(md) {
                            padding: 0 32px 0 16px;
                        }

                        border: 1px solid $grey3;

                        &:not(:last-child) {
                            border-bottom: transparent;
                        }

                        @include font-autoline-rem(14px);
                        height: 40px !important;
                        background: $white;
                        color: $font-black;
                        opacity: 1;
                        text-transform: uppercase;
                        justify-content: left;
                        letter-spacing: normal;

                        &.mdc-tab--active {
                            background-color: $offwhite2;
                            font-family: "Graphik Semibold";
                            color: $font-black;

                            @include media-breakpoint-up(md) {
                                &::after {
                                    position: absolute;
                                    font-family: "smart-icon";
                                    content: "\e923";
                                    transform: rotate(-90deg);
                                    right: 12px;
                                    top: 8px;
                                }
                            }

                            .mdc-tab__text-label {
                                color: $font-black;
                            }
                        }

                        &.cdk-keyboard-focused,
                        &:hover,
                        &:focus {
                            border: 1px solid $blue4;
                        }

                        .mdc-tab__content {
                            justify-content: start;
                            width: 100%;
                        }
                    }

                    .mdc-tab-indicator .mdc-tab-indicator__content {
                        display: none;
                    }
                }
            }
        }

        .mat-mdc-tab-body-wrapper {
            width: 100%;
        }

        .mat-mdc-tab-header-pagination-chevron {
            border-color: $black !important;
        }
    }
}

.error-validation {
    border-color: $red4;

    .ng-select-container {
        border-color: $red4 !important;
    }
}


.custom-checkbox {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;

    .custom-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;

        &:checked~.custom-control-label::before {
            background-color: $blue;
            border: 1px solid $grey7;
        }

        &:disabled~.custom-control-label::before {
            background-color: $grey4;
            border: 1px solid $grey7;
        }

        &:checked~.custom-control-label::after {
            content: "";
            position: absolute;
            display: block;
            left: -16px;
            top: 4px;
            width: 4px;
            height: 9px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

        &:focus~.custom-control-label::before {
            outline: 2px solid $blue4;
            outline-offset: 1px;
        }


    }

    .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        display: inline-block;
        color: $font-black;
        @include font-line-rem(14px, 22px);
        cursor: pointer;

        &:before {
            position: absolute;
            top: 0.25rem;
            left: -20px;
            display: block;
            width: 12px;
            height: 12px;
            pointer-events: none;
            content: "";
            background-color: $white;
            border: $grey7 solid 1px;
            border-radius: 2px;
        }

        &::after {
            position: absolute;
            top: 0.25rem;
            left: -20px;
            display: block;
            width: 12px;
            height: 12px;
            content: "";
            background: no-repeat 50%/50% 50%;
        }
    }
}

.basic-details-container {

    .label-heading {
        @include font-autoline-rem(24px);
        color: $font-black;
        font-family: 'Graphik Regular';
    }
}

.nodata-container {
    padding: 0 3%;

    .label-heading1 {
        @include font-autoline-rem(24px);
        font-family: 'Graphik Regular';
    }
}

.expandable-tab-container {
    mat-tab-group {
        mat-tab-header {
            .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                    .mat-mdc-tab-labels {
                        .mat-mdc-tab {
                            &.mat-mdc-focus-indicator {
                                padding: 1px;
                                height: 34px;
                                @include rem("margin-right", 24px);

                                @include media-breakpoint-up(sm) {
                                    @include rem("margin-right", 40px);
                                }
                            }


                            .mdc-tab__text-label {
                                text-transform: none;
                            }
                        }
                    }

                    .mdc-tab-indicator .mdc-tab-indicator__content {
                        display: block !important;
                        border: 2px solid $grey5;
                    }
                }
            }
        }

        .mat-mdc-tab-body-wrapper {
            @include rem("margin-bottom", 8px);

            mat-tab-body {
                .mat-mdc-tab-body-content {
                    overflow: hidden;
                }
            }
        }
    }
}

.btn-link {
    @include font-autoline-rem(14px);
    color: $blue3;
}

.indicator-tabs {
    .mat-mdc-tab-body.mat-mdc-tab-body-active {
        z-index: 101;
    }
}

.action-right {
    right: 0;
    z-index: 2;
    top: -32px;

    @include media-breakpoint-up(xl) {
        top: 0;
        right: 34px;
    }
}

.section-header {
    font-family: 'Graphik Semibold';
    @include font-autoline-rem(24px);
}

.switch-toggle {
    .swicth-label {
        @include font-autoline-rem(14px);
        color: $grey7;
    }
}

.smart-progress-bar {
    .smart-probability-bar {
        height: 16px;
        border-radius: 2px;
    }

    .bar-filled {
        background-color: $dark-orange;
    }

    .progress-bar-success {
        background-color: $green-success;
    }

    .progress-bar-warning {
        background-color: $dark-orange5;
    }

    .progress-bar-danger {
        background-color: $red;
    }

    .progress-bar-label {
        color: $grey7;
    }

    .progress-bar-tagged {
        background: $dark-blue;
    }

    .progress-bar-untagged {
        background: $rose;
    }

    .progress-bar-lightblue {
        background: $light-blue3;
    }

    .progress-bar-yellow {
        background: $dark-orange5;
    }
}

.dropdown-tags {
    @include font-autoline-rem(12px);
    background-color: $grey2;
    color: $font-black;
    border-radius: 16px;

    &.tag-block {
        max-width: 150px;

        .tag-content {
            max-width: 90%;
        }
    }

    .smart-btn {
        font-size: 10px;
    }
}

.input-group {
    .form-control {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
}

.smart-multi-chkbox-option {
    &.form-check {
        .form-check-input {
            border: 1px solid $blue;
            border-radius: 2px;
        }
    }

    .option-checked {
        background-color: $blue;
    }
}

.shared-lock-filter {
    .filter-card-container .filter-card-body {
        height: calc(100vh - 148px);
    }
}

.mat-mdc-autocomplete-panel {
    padding: 0 !important;

    .mdc-list-item__primary-text {
        letter-spacing: normal !important;

        @include media-breakpoint-down(sm) {
            white-space: normal !important;
        }
    }

    &.auto-complete-search {

        mat-option {
            height: 36px;
            line-height: 36px;
            min-height: auto;

            .mdc-list-item__primary-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                @include font-autoline-rem(12px);

                @include media-breakpoint-down(sm) {
                    white-space: normal !important;
                }
            }

            &.mdc-list-item--disabled {
                color: $grey7;

                .mdc-list-item__primary-text {
                    opacity: 1;
                }
            }
        }
    }
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.info-icon {
    color: $blue3;
    @include font-line-rem(14px);
}

.filtered-tags {
    list-style: none;

    li {
        @include font-autoline-rem(12px);
        margin-bottom: 4px;
        color: $grey7;

        &.filter-sub-title {
            font-family: "Graphik Bold";
        }

        &.filter-items {
            @include font-autoline-rem(14px);
            background: $grey6;
            color: $font-black;
            padding: 2px 8px;

            .btn {
                font-size: 8px;
            }
        }
    }
}

.mat-mdc-menu-content {
    .mat-mdc-menu-item {
        .mat-mdc-menu-item-text {
            font-size: 0.875rem !important;
            line-height: 1.3125rem !important;
        }
    }
}

.reverse {
    transform: rotate(180deg);
}

.filtered-tags {
    list-style: none;

    li {
        @include font-autoline-rem(12px);
        margin-bottom: 4px;
        color: $grey7;

        &.filter-sub-title {
            font-family: "Graphik Bold";
        }

        &.filter-items {
            @include font-autoline-rem(14px);
            background: $grey6;
            color: $font-black;
            padding: 2px 8px;

            .btn {
                font-size: 8px;
            }
        }
    }
}

.hide-content {
    overflow: hidden;
    height: 28px;

    .show-more {
        top: -2px;
        right: 0;
        position: absolute;
    }
}

.show-content {
    height: auto;
    margin-bottom: 20px;
    width: 100%;
    word-wrap: break-word;

    .show-more-btn {
        &:before {
            display: none;
        }

        .smart-icon-arrow-down {
            transform: rotate(180deg);
        }
    }
}

.show-more-btn {
    background: $blue3;
    color: $white;
    font-family: "Graphik Medium";
    height: 28px;
    @include font-autoline-rem(14px);
    border-left: 4px solid $white !important;

    &:before {
        content: "...";
    }

    .smart-icon-arrow-down {
        font-size: 8px;
    }
}

.filter-loader {
    background-color: $black;
    opacity: 0.4;
    z-index: 999;

    .spinner-border {
        color: $white;
    }
}

.mat-mdc-option {
    .mat-mdc-option-pseudo-checkbox {
        display: none;
    }
}

.mat-datepicker-content .mat-datepicker-close-button.cdk-focused {
    @include media-breakpoint-down(md) {
        margin-top: -35px;
        margin-left: 16px;
    }
}

.info {
    @include font-autoline-rem(14px);
}