/*
Font Graphik
Font face created for selected fonts of Graphik
1. Graphik Thin
2. Graphik Light
3. Graphik Regular
Other variants can be created by adjusting font-style and font-weight
*/

@font-face {
    font-family: 'Graphik Bold';
    src: url('/assets/fonts/graphik/Graphik-Bold-Web.eot');
    src: url('/assets/fonts/graphik/Graphik-Bold-Web.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/graphik/Graphik-Bold-Web.woff2') format('woff2'), url('/assets/fonts/graphik/Graphik-Bold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

.graphik-bold {
    font-family: 'Graphik Bold' !important;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Graphik Semibold';
    src: url('/assets/fonts/graphik/Graphik-Semibold-Web.eot');
    src: url('/assets/fonts/graphik/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/graphik/Graphik-Semibold-Web.woff2') format('woff2'), url('/assets/fonts/graphik/Graphik-Semibold-Web.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

.graphik-semi-bold {
    font-family: 'Graphik Semibold' !important;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Graphik Medium';
    src: url('/assets/fonts/graphik/Graphik-Medium-Web.eot');
    src: url('/assets/fonts/graphik/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/graphik/Graphik-Medium-Web.woff2') format('woff2'), url('/assets/fonts/graphik/Graphik-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

.graphik-medium {
    font-family: 'Graphik Medium' !important;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Graphik Regular';
    src: url('/assets/fonts/graphik/Graphik-Regular-Web.eot');
    src: url('/assets/fonts/graphik/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/graphik/Graphik-Regular-Web.woff2') format('woff2'), url('/assets/fonts/graphik/Graphik-Regular-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

.graphik-regular {
    font-family: 'Graphik Regular' !important;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Graphik Regular Italic';
    src: url('/assets/fonts/graphik/Graphik-RegularItalic-Web.eot');
    src: url('/assets/fonts/graphik/Graphik-RegularItalic-Web.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/graphik/Graphik-RegularItalic-Web.woff2') format('woff2'), url('/assets/fonts/graphik/Graphik-RegularItalic-Web.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    font-display: swap;
}

.graphik-regular-italic {
    font-family: 'Graphik Regular Italic';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
}