.smart-stepper {

    .mat-horizontal-stepper-header-container {
        pointer-events: none;
        justify-content: center;

        .mat-horizontal-stepper-header {
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                padding: 0 6px;
            }
        }
    }

    .mat-vertical-stepper-header {
        pointer-events: none;
        padding: 20px 10px;
    }

    .mat-step-icon-state-done {
        background-color: $green4;
    }

    .mat-stepper-horizontal-line {
        border-style: none;
        flex: none;
    }

    .mat-step-icon-state-edit {
        background-color: $grey3;
    }

    .mat-step-icon {
        height: 16px;
        width: 16px;

        .mat-step-icon-content {
            font-size: 10px;
        }

        .mat-icon {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }
    }

    .mat-step-header {
        .mat-step-text-label {
            font-family: 'Graphik Regular';
            color: $black;
            @include font-autoline-rem(12px);
        }

        .mat-step-label-selected {
            border-bottom: 3px solid $black;
        }

        &:not(:last-child)::after {
            font-family: "smart-icon" !important;
            content: "\e923";
            position: relative;
            left: 10px;
            transform: rotate(270deg);
            font-size: 10px;

            @include media-breakpoint-down(md) {
                left: 6px;
            }
        }
    }

    .mat-vertical-content-container {
        margin-left: 20px;

        &::before {
            left: -4px;
        }
    }

}

.stepper-footer {
    border-top: 1px solid $grey12;
    margin-top: 40px;
    padding-top: 30px;

    @include media-breakpoint-down(md) {
        margin-top: 20px;
        padding-top: 15px;
    }
}

.smart-vertical-stepper.mat-stepper-vertical {
    .mat-step {
        font-family: 'Graphik Regular';

        .mat-vertical-stepper-header {
            height: 32px;
            padding: 0 0 12px 0;
        }

        .mat-step-header {
            +.mat-vertical-content-container {
                .mat-vertical-stepper-content {
                    overflow: visible !important;
                    visibility: visible !important;
                    height: unset !important;

                    .mat-vertical-content {
                        padding: 0 12px 12px 24px;
                    }
                }
            }

            font-family: "Graphik Medium";
            @include font-autoline-rem(14px);
        }


        .mat-step-header[aria-disabled="true"] {
            +.mat-vertical-content-container {
                .mat-vertical-stepper-content {
                    .stepper-form {
                        visibility: hidden;
                    }

                    .stepper-form-label {
                        color: $grey18;
                    }
                }
            }

            color: $light-grey1;
        }

        .mat-step-icon {
            border: 1px solid $light-grey1;
            background-color: transparent;
            position: relative;
            width: 12px;
            height: 12px;
            margin-right: 16px;

            &.mat-step-icon-state-edit,
            &.mat-step-icon-state-done {
                border-color: transparent;

                &::after {
                    position: absolute;
                    content: "";
                    width: 9px;
                    height: 9px;
                    background-color: $blue3;
                    border-radius: 50%;
                    left: 1px;
                    top: 1px;
                    outline: 1px solid $blue3;
                    outline-offset: 1px;
                }
            }

            .mat-step-icon-content {
                display: none;
            }
        }

        .mat-vertical-content-container {
            margin-left: 6px;
        }

        .mat-stepper-vertical-line::before {
            border-color: $light-grey1;
            top: -22px;
            bottom: -10px;
        }

        .mat-step-header {
            pointer-events: none;
            cursor: pointer;
            background: transparent;

            .mat-step-label {
                font-family: "Graphik Medium";
                position: relative;
                display: flex;
                align-items: center;
                width: 300px;
                height: 100%;
                color: $light-grey1;
                @include font-autoline-rem(14px);

                &::before {
                    position: absolute;
                    content: "";
                    border: 1px solid $light-grey1;
                    border-right: 0;
                    left: 0;
                    right: 16px;
                    top: 0;
                    bottom: 0;
                }

                &::after {
                    position: absolute;
                    content: '';
                    right: 5px;
                    width: 24px;
                    height: 24px;
                    border-top: 1px solid $light-grey1;
                    border-left: 1px solid $light-grey1;
                    transform: rotate(135deg);
                }

                &.mat-step-label-active {
                    color: $white;

                    .mat-step-text-label {
                        border-color: $blue3;
                        z-index: 1;
                    }

                    &::before {
                        border-color: $blue3;
                        background-color: $blue3;
                    }

                    &::after {
                        border-top-color: $blue3;
                        border-left-color: $blue3;
                        background-color: $blue3;
                    }
                }

                .mat-step-text-label {
                    margin-left: 16px;
                }
            }
        }

    }
}