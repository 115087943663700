@font-face {
  font-family: "smart-icon";
  src: url("/assets/fonts/smart/smart.eot");
  src: url("/assets/fonts/smart/smart.eot") format("embedded-opentype"),
    url("/assets/fonts/smart/smart.ttf") format("truetype"), url("/assets/fonts/smart/smart.woff") format("woff"),
    url("/assets/fonts/smart/smart.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="smart-icon-"],
[class*=" smart-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "smart-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.smart-icon-menu:before {
  content: "\e90f";
}

.smart-icon-search-lens:before {
  content: "\e90b";
}

.smart-icon-audit-trail:before {
  content: "\e909";
}

.smart-icon-upload:before {
  content: "\e90e";
}

.smart-icon-library:before {
  content: "\e90d";
}

.smart-icon-gear:before {
  content: "\e914";
}

.smart-icon-notification:before {
  content: "\e915";
}

.smart-icon-search-biglens:before {
  content: "\e912";
}

.smart-icon-calendar:before {
  content: "\e913";
}

.smart-icon-add:before {
  content: "\e910";
}

.smart-icon-maintain-masters:before {
  content: "\e911";
}

.smart-icon-export:before {
  content: "\e90c";
}

.smart-icon-edit:before {
  content: "\e90a";
}

.smart-icon-drag:before {
  content: "\e908";
}

.smart-icon-resource:before {
  content: "\e900";
}

.smart-icon-happyface:before {
  content: "\e901";
}

.smart-icon-middleface:before {
  content: "\e902";
}

.smart-icon-sadface:before {
  content: "\e903";
}

.smart-icon-shoutingface:before {
  content: "\e904";
}

.smart-icon-veryhappyface:before {
  content: "\e906";
}

.smart-icon-updown:before {
  content: "\e905";
}

.smart-icon-arrow:before {
  content: "\e916";
}

.smart-icon-hamburger-menu:before {
  content: "\e917";
}

.smart-icon-spanner:before {
  content: "\e918";
}

.smart-icon-moreInfo:before {
  content: "\e919";
}

.smart-icon-trash:before {
  content: "\e91a";
}

.smart-icon-checkmark:before {
  content: "\e91b";
}

.smart-icon-close:before {
  content: "\e91c";
}

.smart-icon-sort-triangle:before {
  content: "\e91d";
}

.smart-icon-sort:before {
  content: "\e91e";
}

.smart-icon-download:before {
  content: "\e907";
}

.smart-icon-plus-icon:before {
  content: "\e91f";
}

.smart-icon-more:before {
  content: "\e920";
}

.smart-icon-comments:before {
  content: "\e921";
}

.smart-icon-star:before {
  content: "\e922";
}

.smart-icon-arrow-down:before {
  content: "\e923";
}

.smart-icon-filter-o:before {
  content: "\e924";
}

.smart-icon-filter:before {
  content: "\e925";
}

.smart-icon-onsite:before {
  content: "\e926";
}

.smart-icon-line:before {
  content: "\e927";
}

.smart-icon-arrow_sort_up:before {
  content: "\e928";
}

.smart-icon-PlusIcon:before {
  content: "\e910";
}

.smart-icon-more-details-icon:before {
  content: "\e929";
}

.smart-icon-export:before {
  content: "\e92a";
}

.smart-icon-minimize-icon:before {
  content: "\e92b";
}

.smart-icon-fullscreen:before {
  content: "\e92c";
}

.smart-icon-admin:before {
  content: "\e92e";
}

.smart-icon-access:before {
  content: "\e92f";
}

.smart-icon-collapse-show:before {
  content: "\e92d";
}

.smart-icon-scheduled-task:before {
  content: "\e930";
}

.smart-icon-more-action:before {
  content: "\e931";
}

.smart-icon-star-empty:before {
  content: "\e932";
}

.smart-icon-bulk-import:before {
  content: "\e933";
}

.smart-icon-reload:before {
  content: "\e934";
}

.smart-icon-locked2:before {
  content: "\e935";
}

.smart-icon-tag-o:before {
  content: "\e936";
}

.smart-icon-world:before {
  content: "\e937";
}

.smart-icon-timer-clock:before {
  content: "\e938";
}

.smart-icon-exit:before {
  content: "\e939";
}

.smart-icon-erase:before {
  content: "\e93a";
}

.smart-icon-open-double-arrow:before {
  content: "\e93b";
}

.smart-icon-close-double-arrow:before {
  content: "\e93c";
}

.smart-icon-skills:before {
  content: "\e93d";
}

.smart-icon-location:before {
  content: "\e93e";
}

.smart-icon-level:before {
  content: "\e93f";
}

.smart-icon-start_date:before {
  content: "\e940";
}

.smart-icon-resubmit:before {
  content: "\e941";
}

.smart-icon-edit:before {
  content: "\e942";
}

.smart-icon-copy:before {
  content: "\e943";
}

.smart-icon-project:before {
  content: "\e944";
}

.smart-icon-industry-expert:before {
  content: "\e945";
}