@use "sass:math";
@import "variables";
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins/_breakpoints";

$rem-base-font: 16px !default;

@mixin font-line-rem($font-size, $line-height: 0) {
    font-size: math.div($font-size, $rem-base-font) * 1rem;

    @if ($line-height > 0) {
        line-height: math.div($line-height, $rem-base-font) * 1rem;
    }
}

@mixin font-autoline-rem($font-size) {
    font-size: math.div($font-size, $rem-base-font) * 1rem;
    line-height: math.div($font-size * 1.5, $rem-base-font) * 1rem;
}

//Common rem converter
$baseline_px: 16px;

@mixin rem($property, $px_values) {
    // Convert the baseline into rems
    $baseline_rem: math.div($baseline_px, 1rem);

    // If there is only one (numeric) value, return the property/value line for it.
    @if type-of($px_values) == "number" {
        #{$property}: math.div($px_values, $baseline_rem);
    }

    // If there is more than one value, create a list and perform equations on each value
    @else {
        // Create an empty list that we can dump values into
        $rem_values: ();

        @each $value in $px_values {
            // If the value is zero, return 0
            @if $value==0 {
                $rem_values: append($rem_values, $value);
            }

            // If the value is not zero, convert it from px to rem
            @else {
                $rem_values: append($rem_values, math.div($value, $baseline_rem));
            }
        }

        // Return the property and its list of converted values
        #{$property}: $rem_values;
    }
}
