.add-tags-modal {
  min-height: auto !important;

  .close-btn {
    top: 10px;
    right: 10px;
    color: $blue6;
    z-index: 1;
  }

  .mat-mdc-dialog-container {
    padding: 20px;

    @include media-breakpoint-up(lg) {
      @include rem("padding", 14px 16px 16px 16px);
    }

    .mdc-dialog__title {
      color: $primary-blue;
      @include font-autoline-rem(24px);
      font-family: "Graphik Semibold";
    }

    .mdc-dialog__content {
      letter-spacing: normal;
      color: $font-black;
    }
  }

  .mat-mdc-dialog-actions {
    padding: 20px 0 0;
    min-height: auto;
    margin-bottom: 0;

    .saveButton {
      @include font-line-rem(12px);
      border-radius: 3px;
      background: $orange;
      border: 1px solid $orange;
      color: $primary-blue;
      margin-left: auto;

      &:hover {
        outline: 2px solid $blue7;
        outline-offset: 1px;
      }

      &:disabled {
        opacity: 50%;
      }
    }

    @include media-breakpoint-up(lg) {
      @include rem("padding", 28px 16px 24px 0px);
      margin-bottom: 0;
    }
  }
}

.rejection-alert-modal {
  .mat-mdc-dialog-container {
    padding: 0px;

    .mat-mdc-dialog-content {
      @include media-breakpoint-up(lg) {
        @include rem("padding", 14px 50px 16px 50px);
      }

      @include media-breakpoint-down(md) {
        padding: 20px;
      }

      margin: 0;
      max-height: 80vh;
    }
  }
}

.smart-modal {

  .smart-primary-btn,
  .smart-btn-link {
    @include rem("padding", 8px 20px);
  }

  .btn-close {
    @include rem("padding", 20px);
    top: 0;
    right: 0;
    color: $primary-blue;
    position: absolute;
    opacity: 1;
    background: none;
    z-index: 1;
  }

  .mat-mdc-dialog-content {
    &.mdc-dialog__content {
      @include rem("padding-bottom", 4px);
      @include rem("padding-top", 0px);
      letter-spacing: normal;
    }

    @include media-breakpoint-down(xs) {
      max-height: 53vh;
    }
  }

  .mat-mdc-dialog-container {
    position: relative;

    .mat-mdc-dialog-surface {
      @include rem("padding", 10px);

      @include media-breakpoint-up(lg) {
        @include rem("padding", 32px 16px);
      }

      .mdc-dialog__title {
        color: $primary-blue;
        @include font-autoline-rem(24px);
        font-family: "Graphik Semibold";
        @include rem("padding", 0px 0px 0px 24px);
        letter-spacing: normal;

        &.sub-title {
          @include rem("padding", 0px);
          @include font-autoline-rem(16px);
          color: $font-black;
        }

        &::before {
          display: none;
        }
      }


      .info {
        @include font-autoline-rem(14px);
        color: $grey7;

        .sub-info {
          font-family: 'Graphik Semibold';
        }
      }

      @include media-breakpoint-down(xs) {
        overflow: hidden;
      }
    }
  }

  .mat-mdc-dialog-actions {
    @include media-breakpoint-up(lg) {
      @include rem("padding", 28px 24px 0px 0px);
      margin-bottom: 0;
    }

    @include rem("padding", 16px 24px 16px 8px);
  }
}

.personalization-modal {
  @media(max-height:680px) {
    margin-top: 10px !important;
  }

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      padding: 0;
    }
  }
}