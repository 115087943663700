/*
Font Graphik
Font face created for selected fonts of Graphik
1. Graphik Thin
2. Graphik Light
3. Graphik Regular
Other variants can be created by adjusting font-style and font-weight
*/
@font-face {
  font-family: "Graphik Bold";
  src: url("/assets/fonts/graphik/Graphik-Bold-Web.eot");
  src: url("/assets/fonts/graphik/Graphik-Bold-Web.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/graphik/Graphik-Bold-Web.woff2") format("woff2"), url("/assets/fonts/graphik/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
.graphik-bold {
  font-family: "Graphik Bold" !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Semibold";
  src: url("/assets/fonts/graphik/Graphik-Semibold-Web.eot");
  src: url("/assets/fonts/graphik/Graphik-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/graphik/Graphik-Semibold-Web.woff2") format("woff2"), url("/assets/fonts/graphik/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
.graphik-semi-bold {
  font-family: "Graphik Semibold" !important;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Medium";
  src: url("/assets/fonts/graphik/Graphik-Medium-Web.eot");
  src: url("/assets/fonts/graphik/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/graphik/Graphik-Medium-Web.woff2") format("woff2"), url("/assets/fonts/graphik/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
.graphik-medium {
  font-family: "Graphik Medium" !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Regular";
  src: url("/assets/fonts/graphik/Graphik-Regular-Web.eot");
  src: url("/assets/fonts/graphik/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/graphik/Graphik-Regular-Web.woff2") format("woff2"), url("/assets/fonts/graphik/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
.graphik-regular {
  font-family: "Graphik Regular" !important;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Regular Italic";
  src: url("/assets/fonts/graphik/Graphik-RegularItalic-Web.eot");
  src: url("/assets/fonts/graphik/Graphik-RegularItalic-Web.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/graphik/Graphik-RegularItalic-Web.woff2") format("woff2"), url("/assets/fonts/graphik/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
.graphik-regular-italic {
  font-family: "Graphik Regular Italic";
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "smart-icon";
  src: url("/assets/fonts/smart/smart.eot");
  src: url("/assets/fonts/smart/smart.eot") format("embedded-opentype"), url("/assets/fonts/smart/smart.ttf") format("truetype"), url("/assets/fonts/smart/smart.woff") format("woff"), url("/assets/fonts/smart/smart.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=smart-icon-],
[class*=" smart-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "smart-icon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.smart-icon-menu:before {
  content: "\e90f";
}

.smart-icon-search-lens:before {
  content: "\e90b";
}

.smart-icon-audit-trail:before {
  content: "\e909";
}

.smart-icon-upload:before {
  content: "\e90e";
}

.smart-icon-library:before {
  content: "\e90d";
}

.smart-icon-gear:before {
  content: "\e914";
}

.smart-icon-notification:before {
  content: "\e915";
}

.smart-icon-search-biglens:before {
  content: "\e912";
}

.smart-icon-calendar:before {
  content: "\e913";
}

.smart-icon-add:before {
  content: "\e910";
}

.smart-icon-maintain-masters:before {
  content: "\e911";
}

.smart-icon-export:before {
  content: "\e90c";
}

.smart-icon-edit:before {
  content: "\e90a";
}

.smart-icon-drag:before {
  content: "\e908";
}

.smart-icon-resource:before {
  content: "\e900";
}

.smart-icon-happyface:before {
  content: "\e901";
}

.smart-icon-middleface:before {
  content: "\e902";
}

.smart-icon-sadface:before {
  content: "\e903";
}

.smart-icon-shoutingface:before {
  content: "\e904";
}

.smart-icon-veryhappyface:before {
  content: "\e906";
}

.smart-icon-updown:before {
  content: "\e905";
}

.smart-icon-arrow:before {
  content: "\e916";
}

.smart-icon-hamburger-menu:before {
  content: "\e917";
}

.smart-icon-spanner:before {
  content: "\e918";
}

.smart-icon-moreInfo:before {
  content: "\e919";
}

.smart-icon-trash:before {
  content: "\e91a";
}

.smart-icon-checkmark:before {
  content: "\e91b";
}

.smart-icon-close:before {
  content: "\e91c";
}

.smart-icon-sort-triangle:before {
  content: "\e91d";
}

.smart-icon-sort:before {
  content: "\e91e";
}

.smart-icon-download:before {
  content: "\e907";
}

.smart-icon-plus-icon:before {
  content: "\e91f";
}

.smart-icon-more:before {
  content: "\e920";
}

.smart-icon-comments:before {
  content: "\e921";
}

.smart-icon-star:before {
  content: "\e922";
}

.smart-icon-arrow-down:before {
  content: "\e923";
}

.smart-icon-filter-o:before {
  content: "\e924";
}

.smart-icon-filter:before {
  content: "\e925";
}

.smart-icon-onsite:before {
  content: "\e926";
}

.smart-icon-line:before {
  content: "\e927";
}

.smart-icon-arrow_sort_up:before {
  content: "\e928";
}

.smart-icon-PlusIcon:before {
  content: "\e910";
}

.smart-icon-more-details-icon:before {
  content: "\e929";
}

.smart-icon-export:before {
  content: "\e92a";
}

.smart-icon-minimize-icon:before {
  content: "\e92b";
}

.smart-icon-fullscreen:before {
  content: "\e92c";
}

.smart-icon-admin:before {
  content: "\e92e";
}

.smart-icon-access:before {
  content: "\e92f";
}

.smart-icon-collapse-show:before {
  content: "\e92d";
}

.smart-icon-scheduled-task:before {
  content: "\e930";
}

.smart-icon-more-action:before {
  content: "\e931";
}

.smart-icon-star-empty:before {
  content: "\e932";
}

.smart-icon-bulk-import:before {
  content: "\e933";
}

.smart-icon-reload:before {
  content: "\e934";
}

.smart-icon-locked2:before {
  content: "\e935";
}

.smart-icon-tag-o:before {
  content: "\e936";
}

.smart-icon-world:before {
  content: "\e937";
}

.smart-icon-timer-clock:before {
  content: "\e938";
}

.smart-icon-exit:before {
  content: "\e939";
}

.smart-icon-erase:before {
  content: "\e93a";
}

.smart-icon-open-double-arrow:before {
  content: "\e93b";
}

.smart-icon-close-double-arrow:before {
  content: "\e93c";
}

.smart-icon-skills:before {
  content: "\e93d";
}

.smart-icon-location:before {
  content: "\e93e";
}

.smart-icon-level:before {
  content: "\e93f";
}

.smart-icon-start_date:before {
  content: "\e940";
}

.smart-icon-resubmit:before {
  content: "\e941";
}

.smart-icon-edit:before {
  content: "\e942";
}

.smart-icon-copy:before {
  content: "\e943";
}

.smart-icon-project:before {
  content: "\e944";
}

.smart-icon-industry-expert:before {
  content: "\e945";
}

.ng-select.smart-ng-select .ng-select-container {
  border-radius: 2px;
  border: 1px solid #929292;
  min-height: 35px;
  height: 35px;
}
.ng-select.smart-ng-select .ng-select-container .ng-value-container {
  padding-right: 24px;
}
.ng-select.smart-ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #757575;
  overflow: auto;
}
.ng-select.smart-ng-select .ng-select-container .ng-value-container .ng-value .ng-value-label {
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.ng-select.smart-ng-select.autocomplete .ng-arrow-wrapper .ng-arrow {
  font-family: smart-icon;
  border: 0;
  right: 8px;
}
.ng-select.smart-ng-select.autocomplete .ng-arrow-wrapper .ng-arrow::after {
  content: "\e90b";
}
.ng-select.smart-ng-select.ng-select-focused, .ng-select.smart-ng-select:hover {
  outline: 2px solid #006796;
  outline-offset: 0;
}
.ng-select.smart-ng-select.multi-select-inline-search .ng-value-label {
  max-width: 80%;
}
.ng-select.smart-ng-select.multi-select-inline-search .ng-input {
  height: 20px;
  font-family: "Graphik Regular";
  font-size: 0.875rem;
}

@media (max-width: 575.98px) {
  .smart-ng-select .ng-dropdown-panel-items .ng-option {
    height: auto;
  }
}
.smart-ng-select .ng-dropdown-panel-items .ng-option .ng-option-label {
  color: #333333;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
@media (max-width: 575.98px) {
  .smart-ng-select .ng-dropdown-panel-items .ng-option .ng-option-label {
    white-space: normal;
  }
}

ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items {
  max-height: 150px;
  max-height: 100px;
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option {
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  height: 36px;
  padding: 0.625rem;
  border-bottom: 1px solid #cccccc;
}
@media (max-width: 575.98px) {
  ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option {
    height: auto;
  }
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option:hover {
  background-color: #f0f0f0 !important;
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #f0f0f0 !important;
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #333333;
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: #f0f0f0;
}
@media (max-width: 575.98px) {
  ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option-label {
    white-space: normal;
  }
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-header {
  background: #eaeaea;
  padding: 0.3125rem;
  position: relative;
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-header::before {
  content: "\e90b";
  font-family: "smart-icon";
  position: absolute;
  color: #666666;
  font-size: 1.25rem;
  line-height: 1.875rem;
  top: 5px;
  left: 12px;
}
ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-header input {
  border: none;
  border-radius: 2px;
  padding: 0.3125rem 0.625rem 0.3125rem 2.1875rem;
  height: 30px;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #666666;
}

ng-select.filter-dropdown-selection .ng-select-container {
  border: 1px solid #929292;
}
ng-select.filter-dropdown-selection .ng-select-container .ng-clear-wrapper {
  display: none;
}
ng-select.filter-dropdown-selection .ng-select-container .ng-value-container .ng-placeholder {
  overflow: auto;
  color: #757575 !important;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
ng-select.filter-dropdown-selection.ng-select-focused, ng-select.filter-dropdown-selection:hover {
  outline: 2px solid #006796;
  outline-offset: 1px;
}

ng-select.filter-clear-selection .ng-select-container .ng-clear-wrapper {
  display: block !important;
}

.selection-control.filter-dropdown-selection .ng-dropdown-header,
.ng-select.filter-dropdown-selection .ng-dropdown-header {
  padding: 0.3125rem;
  position: relative;
  width: 60%;
  border-bottom: none;
}
.selection-control.filter-dropdown-selection .ng-dropdown-header::before,
.ng-select.filter-dropdown-selection .ng-dropdown-header::before {
  display: none;
}
.selection-control.filter-dropdown-selection .ng-dropdown-header input,
.ng-select.filter-dropdown-selection .ng-dropdown-header input {
  border: 1px solid #f0f0f0;
  padding: 0.3125rem 0.625rem;
  font-style: italic;
  color: #333333;
}
.selection-control.filter-dropdown-selection .ng-dropdown-header input::placeholder,
.ng-select.filter-dropdown-selection .ng-dropdown-header input::placeholder {
  color: #757575;
}
.selection-control.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-header,
.ng-select.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-header {
  background: none;
}
.selection-control .ng-value-container,
.ng-select .ng-value-container {
  width: 100%;
}
.selection-control .ng-value-label,
.ng-select .ng-value-label {
  font-family: "Graphik Regular";
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.selection-control .ng-input,
.ng-select .ng-input {
  width: 0;
  height: 0;
}
.selection-control.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option,
.ng-select.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option {
  border-bottom: none;
}
@media (max-width: 575.98px) {
  .selection-control.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option,
  .ng-select.ng-dropdown-panel.filter-dropdown-selection .ng-dropdown-panel-items .ng-option {
    white-space: normal;
  }
}
.selection-control .ng-arrow-wrapper,
.ng-select .ng-arrow-wrapper {
  padding-right: 0.75rem;
  padding-top: 0;
  position: absolute;
  right: 0;
}
.selection-control .ng-arrow-wrapper .ng-arrow,
.ng-select .ng-arrow-wrapper .ng-arrow {
  font-family: "smart-icon" !important;
  border: 0;
  border-color: #999999;
  font-size: 0.625rem;
  top: auto !important;
}
.selection-control .ng-arrow-wrapper .ng-arrow::before,
.ng-select .ng-arrow-wrapper .ng-arrow::before {
  color: #929292;
  content: "\e923";
}
.selection-control.ng-select-disabled > .ng-select-container,
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f2f2f2;
}

.timepicker-select.ng-select.smart-ng-select .ng-select-container {
  border: 0;
  height: 30px;
  min-height: 30px;
}
.timepicker-select .ng-dropdown-panel-items .ng-option {
  padding: 6px !important;
}
.timepicker-select .ng-arrow-wrapper {
  display: none;
}

.input-group .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Removing scroll for placeholder */
.ng-placeholder::-webkit-scrollbar-thumb {
  display: none;
}

.ng-placeholder::-webkit-scrollbar-track {
  display: none;
}

.ng-placeholder::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.add-tags-modal {
  min-height: auto !important;
}
.add-tags-modal .close-btn {
  top: 10px;
  right: 10px;
  color: #337ab7;
  z-index: 1;
}
.add-tags-modal .mat-mdc-dialog-container {
  padding: 20px;
}
@media (min-width: 992px) {
  .add-tags-modal .mat-mdc-dialog-container {
    padding: 0.875rem 1rem 1rem 1rem;
  }
}
.add-tags-modal .mat-mdc-dialog-container .mdc-dialog__title {
  color: #08103a;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: "Graphik Semibold";
}
.add-tags-modal .mat-mdc-dialog-container .mdc-dialog__content {
  letter-spacing: normal;
  color: #333333;
}
.add-tags-modal .mat-mdc-dialog-actions {
  padding: 20px 0 0;
  min-height: auto;
  margin-bottom: 0;
}
.add-tags-modal .mat-mdc-dialog-actions .saveButton {
  font-size: 0.75rem;
  border-radius: 3px;
  background: #fe4801;
  border: 1px solid #fe4801;
  color: #08103a;
  margin-left: auto;
}
.add-tags-modal .mat-mdc-dialog-actions .saveButton:hover {
  outline: 2px solid #3399cc;
  outline-offset: 1px;
}
.add-tags-modal .mat-mdc-dialog-actions .saveButton:disabled {
  opacity: 50%;
}
@media (min-width: 992px) {
  .add-tags-modal .mat-mdc-dialog-actions {
    padding: 1.75rem 1rem 1.5rem 0rem;
    margin-bottom: 0;
  }
}

.rejection-alert-modal .mat-mdc-dialog-container {
  padding: 0px;
}
.rejection-alert-modal .mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0;
  max-height: 80vh;
}
@media (min-width: 992px) {
  .rejection-alert-modal .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 0.875rem 3.125rem 1rem 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .rejection-alert-modal .mat-mdc-dialog-container .mat-mdc-dialog-content {
    padding: 20px;
  }
}

.smart-modal .smart-primary-btn,
.smart-modal .smart-btn-link {
  padding: 0.5rem 1.25rem;
}
.smart-modal .btn-close {
  padding: 1.25rem;
  top: 0;
  right: 0;
  color: #08103a;
  position: absolute;
  opacity: 1;
  background: none;
  z-index: 1;
}
.smart-modal .mat-mdc-dialog-content {
  max-height: 53vh;
}
.smart-modal .mat-mdc-dialog-content.mdc-dialog__content {
  padding-bottom: 0.25rem;
  padding-top: 0rem;
  letter-spacing: normal;
}
.smart-modal .mat-mdc-dialog-container {
  position: relative;
}
.smart-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  padding: 0.625rem;
  overflow: hidden;
}
@media (min-width: 992px) {
  .smart-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    padding: 2rem 1rem;
  }
}
.smart-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__title {
  color: #08103a;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: "Graphik Semibold";
  padding: 0rem 0rem 0rem 1.5rem;
  letter-spacing: normal;
}
.smart-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__title.sub-title {
  padding: 0rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
}
.smart-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__title::before {
  display: none;
}
.smart-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface .info {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #666666;
}
.smart-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface .info .sub-info {
  font-family: "Graphik Semibold";
}
.smart-modal .mat-mdc-dialog-actions {
  padding: 1rem 1.5rem 1rem 0.5rem;
}
@media (min-width: 992px) {
  .smart-modal .mat-mdc-dialog-actions {
    padding: 1.75rem 1.5rem 0rem 0rem;
    margin-bottom: 0;
  }
}

@media (max-height: 680px) {
  .personalization-modal {
    margin-top: 10px !important;
  }
}
.personalization-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  padding: 0;
}

html,
body {
  font-family: "Graphik Regular" !important;
  color: #000000;
  min-height: 100vh;
}
@media (max-width: 575.98px) {
  html,
  body {
    font-size: 14px;
  }
}

body {
  position: relative;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
}

.profile-menu-list.mat-mdc-menu-panel {
  border-radius: 0;
}
.profile-menu-list.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0.5rem 1rem 1rem;
}
.profile-menu-list.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item {
  min-height: 2rem;
}
.profile-menu-list.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.profile-menu-list.mat-mdc-menu-panel .mat-mdc-menu-content button {
  padding: 0;
}
.profile-menu-list.mat-mdc-menu-panel .mat-mdc-menu-content button .profile-menu-icon {
  font-size: 1rem;
  color: #0172a6;
}
.profile-menu-list.mat-mdc-menu-panel .mat-mdc-menu-content button .icon-label {
  color: #08103a;
}

.dotmenulist.mat-mdc-menu-panel {
  border-radius: 0;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content {
  min-height: 200px;
  padding: 0.3125rem;
  display: flex;
  flex-wrap: wrap;
  width: 250px;
  align-items: baseline;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button {
  padding: 0 0.625rem;
  min-height: 95px;
  width: 80px;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .mat-mdc-menu-item-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: baseline;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-upload {
  font-size: 2.0625rem;
  line-height: 3.125rem;
  margin-bottom: 0.5rem;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-library {
  font-size: 2.375rem;
  line-height: 3.5625rem;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-audit-trail {
  font-size: 1.875rem;
  line-height: 2.8125rem;
  margin-bottom: 0.75rem;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-maintain-masters,
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-download,
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-scheduled-task,
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-more-action,
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .smart-icon-project {
  font-size: 1.875rem;
  line-height: 2.8125rem;
  margin-bottom: 0.5rem;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button .icon-label {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: "Graphik Regular";
  color: #08103a;
}
.dotmenulist.mat-mdc-menu-panel .mat-mdc-menu-content button:hover {
  background-color: #f0f0f0;
  border-radius: 0.125rem;
}

.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  letter-spacing: normal;
}
.mat-mdc-menu-panel.profile-menu-list, .mat-mdc-menu-panel.dotmenulist {
  background: transparent;
  margin-top: -10px;
}
.mat-mdc-menu-panel.profile-menu-list .mat-mdc-menu-content, .mat-mdc-menu-panel.dotmenulist .mat-mdc-menu-content {
  background: #ffffff;
  margin-top: 10px;
}
.mat-mdc-menu-panel.profile-menu-list .mat-mdc-menu-content::before, .mat-mdc-menu-panel.dotmenulist .mat-mdc-menu-content::before {
  content: "";
  position: absolute;
  right: 1rem;
  top: 1px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #ffffff;
}
.mat-mdc-menu-panel.profile-menu-list.no-arrow .mat-mdc-menu-content::before, .mat-mdc-menu-panel.dotmenulist.no-arrow .mat-mdc-menu-content::before {
  display: none;
}

.toggleBtnMenu.mat-mdc-menu-panel {
  border-radius: 0.1875rem;
}
.toggleBtnMenu.mat-mdc-menu-panel .mat-mdc-menu-content button.tab-name {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #08103a;
}
.toggleBtnMenu.mat-mdc-menu-panel .mat-mdc-menu-content button:hover {
  background-color: #f0f0f0;
}

.header-search-dropdown-options.mat-mdc-autocomplete-panel mat-option {
  border-bottom: 1px solid #f2f2f2;
}
.header-search-dropdown-options.mat-mdc-autocomplete-panel mat-option .mdc-list-item__primary-text .dropdown-options a {
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #0072b3;
}
.header-search-dropdown-options.mat-mdc-autocomplete-panel mat-option .mdc-list-item__primary-text .dropdown-options a:hover {
  text-decoration: underline;
}
.header-search-dropdown-options.mat-mdc-autocomplete-panel mat-option .mdc-list-item__primary-text .dropdown-options p {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #333333;
}

.smart-container {
  padding: 0 3%;
}

.page-header-container {
  background: #f0f0f0;
}
.page-header-container .header-section h1 {
  color: #08103a;
  font-size: 2.25rem;
  line-height: 3.375rem;
  font-family: "Graphik Semibold";
}
@media screen and (max-width: 576px) {
  .page-header-container .header-section h1 {
    font-size: 1.5625rem;
    line-height: 2.34375rem;
  }
}

.smart-form-control {
  border-radius: 0.125rem;
  border: 1px solid #cccccc;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #333333;
  padding: 0.375rem 0.625rem;
}
.smart-form-control:hover, .smart-form-control:focus {
  outline: 2px solid #006796;
  outline-offset: 1px;
  box-shadow: none;
}

.smart-btn:hover, .smart-btn:focus {
  outline: 2px solid #006796 !important;
  outline-offset: 2px;
}
.smart-btn.btn-icon {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #0172a6;
}
.smart-btn:disabled {
  border: 0;
}
.smart-btn.btn-danger {
  background-color: #DE2F21;
  color: #ffffff;
}
.smart-btn.btn-warning {
  background-color: #A86800;
  color: #ffffff;
}

.smart-primary-btn {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  background: #0172a6;
  border-radius: 3px;
  padding: 0.375rem 1rem;
}
.smart-primary-btn:disabled, .smart-primary-btn:hover, .smart-primary-btn:focus {
  color: #ffffff;
  background: #0172a6;
  border-radius: 3px;
}
.smart-primary-btn:disabled {
  opacity: 50%;
}

.btn-outline-primary {
  color: #0172a6;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  background: #ffffff;
  border-radius: 3px;
  padding: 0.75rem 0.9375rem;
}
.btn-outline-primary:disabled, .btn-outline-primary:hover, .btn-outline-primary:focus {
  color: #ffffff;
  background: #0172a6 !important;
  border-radius: 3px;
}
.btn-outline-primary:disabled {
  opacity: 50%;
}

.flex-1 {
  flex: 1;
}

a {
  color: #0172a6;
}
a:hover, a:focus {
  outline: 2px solid #006796;
  outline-offset: 0;
}

.page-header-section {
  padding: 0 3%;
  background: #f0f0f0;
}
.page-header-section .page-header-name {
  color: #333333;
  font-size: 2.25rem;
  line-height: 3.375rem;
  font-family: "Graphik Semibold";
  margin: 0 0 1.875rem;
}
.page-header-section .page-sub-header {
  color: #666666;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.page-tab-view {
  background-color: #f0f0f0;
}
.page-tab-view .field-section {
  margin-bottom: 1.875rem;
}
.page-tab-view .field-section .field-label {
  color: #666666;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.page-tab-view .field-section .field-label .smart-icon-moreInfo {
  color: #0172a6;
}
.page-tab-view .checkbox-label-container .show-six-label {
  color: #666666;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.page-tab-view mat-tab-group mat-tab-header {
  padding: 0 3%;
  margin-left: -12px;
  border: none;
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab {
  opacity: 1;
  background-color: #f0f0f0;
  height: 36px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  min-width: 80px;
  border: 2px solid transparent;
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  font-family: "Graphik Regular";
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #333333 !important;
  letter-spacing: normal;
  text-transform: uppercase;
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active {
  background-color: #ffffff;
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active .mdc-tab__text-label {
  font-family: "Graphik Medium";
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab:not(:last-child) {
  margin-right: 0.625rem;
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  overflow: auto;
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab.cdk-keyboard-focused, .page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab:hover, .page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab:focus {
  border: 2px solid #006796;
}
.page-tab-view mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .mdc-tab-indicator .mdc-tab-indicator__content {
  display: none;
}
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body {
  height: 100%;
  background: #ffffff;
}
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content {
  margin-top: 2rem;
}
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content .demand-details,
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content .project-details,
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content .resource-details,
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content .sourcing-details,
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content .userAccess-details {
  max-width: 800px;
}
.page-tab-view mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content .tab-content-header {
  color: #08103a;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-body .mat-mdc-tab-body-content {
  margin-top: 0;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-header {
  padding: 0;
  margin: 0;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab {
  background-color: transparent;
  position: relative;
  height: 3.125rem;
  border: 0;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active .indicator-tab::after {
  content: "";
  position: absolute;
  background-color: #333333;
  left: -10px;
  bottom: 0;
  height: 5px;
  right: -9px;
  z-index: -1;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab {
  margin-bottom: 0.625rem;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab::before {
  content: "";
  position: absolute;
  background-color: #333333;
  left: -10px;
  top: 0;
  width: 10px;
  height: 2.25rem;
  z-index: -1;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.red::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.red::after {
  background-color: #EF1E40;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.green::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.green::after {
  background-color: #50A375;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.darkblue::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.darkblue::after {
  background-color: #08103a;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.lightblue::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.lightblue::after {
  background-color: #0f72a6;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.seablue::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.seablue::after {
  background-color: #15B9BF;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.blue::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.blue::after {
  background-color: #216AA9;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.orange::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.orange::after {
  background-color: #B48F46;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.green2::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.green2::after {
  background-color: #339999;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.purple::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.purple::after {
  background-color: #993399;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.green3::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.green3::after {
  background-color: #669999;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.red5::before, .page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab.red5::after {
  background-color: #F01E40;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .indicator-tab .text-label {
  color: #08103a;
  text-transform: capitalize;
  margin-left: 0.625rem;
}
.page-tab-view mat-tab-group.smart-grouped-tab .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab:hover {
  border: 0;
  outline: 2px solid #006796;
  outline-offset: -2px;
}
.page-tab-view mat-tab-group.smart-grouped-tab.rejection-refusal-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab {
  border: 0;
}
.page-tab-view mat-tab-group.smart-grouped-tab.rejection-refusal-tab .mat-mdc-tab-header-pagination-chevron {
  border-color: #000000 !important;
}
.page-tab-view mat-tab-group.sr-tab .mat-mdc-tab-labels .mat-mdc-tab:last-child {
  display: none;
}

.form-field-container .field-label {
  color: #666666;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.form-field-container.input-field input::-webkit-input-placeholder {
  /* Edge */
  color: #666666;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.form-field-container.input-field input::-ms-input-placeholder {
  /* Internet Explorer */
  color: #666666;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.form-field-container.input-field input::placeholder {
  color: #666666;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

@media screen and (max-height: 320px) {
  .cdk-global-scrollblock {
    position: static;
  }
  .mat-datepicker-popup {
    top: 0px !important;
    left: 0px !important;
  }
  .mat-datepicker-content {
    overflow: auto;
  }
}
.date-picker-container .smart-icon-calendar {
  color: #666666;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  top: 9px;
  right: 22px;
  cursor: pointer;
}
.date-picker-container .smart-icon-erase {
  color: #006796;
  font-size: 0.875rem;
  right: -28px;
  top: 10px;
}
.date-picker-container input::-webkit-input-placeholder {
  /* Edge */
  color: #666666;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.date-picker-container input::-ms-input-placeholder {
  /* Internet Explorer */
  color: #666666;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.date-picker-container input::placeholder {
  color: #666666;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.checkbox-container {
  position: relative;
  padding-left: 2.1875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Show the checkmark when checked */
}
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-container input:focus ~ .checkmark {
  outline: 2px solid #006796;
  outline-offset: 1px;
}
.checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #666666;
  border-radius: 2px;
}
.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-container:hover input ~ .checkmark {
  outline: 2px solid #006796;
  outline-offset: 1px;
}
.checkbox-container input:checked ~ .checkmark {
  background-color: #0f72a6;
  border: 1px solid #666666;
}
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.audit-trail-page .footer-form-action-btn .primary-btn {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  background: #0172a6;
  border-radius: 3px;
  padding: 0.75rem 0.9375rem;
}
.audit-trail-page .footer-form-action-btn .primary-btn:disabled {
  opacity: 50%;
}
.audit-trail-page .footer-form-action-btn .secondary-btn {
  color: #0172a6;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.mat-mdc-radio-group .mat-mdc-radio-button:not(:last-child),
.mat-mdc-radio-group .mat-mdc-checkbox:not(:last-child),
.mat-checkbox-group .mat-mdc-radio-button:not(:last-child),
.mat-checkbox-group .mat-mdc-checkbox:not(:last-child) {
  padding-right: 2rem;
}

.mat-mdc-radio-button .mdc-radio {
  width: 12px;
  height: 12px;
  padding: calc((var(--mdc-radio-state-layer-size) - 12px) / 2) !important;
  margin: -14px -7px -7px -14px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background {
  width: 12px;
  height: 12px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  background-color: #0172a6;
  top: calc(-1 * (var(--mdc-radio-state-layer-size) - 12px) / 2) !important;
  left: calc(-1 * (var(--mdc-radio-state-layer-size) - 12px) / 2) !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__inner-circle {
  border-width: 6px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #0172a6 !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #0172a6 !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #0172a6 !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #0172a6 !important;
  background-color: #0172a6;
}
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: #0172a6 !important;
}
.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: #0172a6 !important;
}
.mat-mdc-radio-button .mdc-form-field {
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}

.mat-mdc-checkbox .mdc-form-field {
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
}
.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin: -12px;
  margin-right: -8px;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  width: 16px;
  height: 16px;
  top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
  left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background svg {
  padding: 1px;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #0072b3 !important;
  background-color: #ffffff !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #C5C3C3;
  background-color: #ffffff;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: #0072b3 !important;
  border-color: #0072b3 !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  background-color: #0072b3 !important;
}

.audit-trail-page .mat-mdc-table,
.smart-table .mat-mdc-table {
  border-collapse: separate;
}
.audit-trail-page .mat-mdc-table thead tr,
.smart-table .mat-mdc-table thead tr {
  background: #586774;
}
.audit-trail-page .mat-mdc-table thead tr th,
.smart-table .mat-mdc-table thead tr th {
  border-right: 1px solid #ffffff;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-family: "Graphik Medium";
  padding: 1.375rem 0.9375rem;
  white-space: nowrap;
}
.audit-trail-page .mat-mdc-table thead tr th .mat-sort-header-arrow,
.smart-table .mat-mdc-table thead tr th .mat-sort-header-arrow {
  color: #ffffff;
  transform: translateY(0) !important;
}
.audit-trail-page .mat-mdc-table thead tr th.text-center .mat-sort-header-container,
.smart-table .mat-mdc-table thead tr th.text-center .mat-sort-header-container {
  justify-content: center;
}
.audit-trail-page .mat-mdc-table thead tr th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.smart-table .mat-mdc-table thead tr th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #ffffff !important;
}
.audit-trail-page .mat-mdc-table thead tr th .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.smart-table .mat-mdc-table thead tr th .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  opacity: 0.75;
}
.audit-trail-page .mat-mdc-table thead tr th .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.smart-table .mat-mdc-table thead tr th .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #0172a6 !important;
}
.audit-trail-page .mat-mdc-table thead tr th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.smart-table .mat-mdc-table thead tr th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  background-color: #eaeaea !important;
  border-color: #eaeaea !important;
}
.audit-trail-page .mat-mdc-table thead tr th .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.smart-table .mat-mdc-table thead tr th .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #0172a6 !important;
}
.audit-trail-page .mat-mdc-table thead tr th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.smart-table .mat-mdc-table thead tr th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.audit-trail-page .mat-mdc-table thead tr th.mat-mdc-table-sticky,
.smart-table .mat-mdc-table thead tr th.mat-mdc-table-sticky {
  padding: 1.375rem 1.875rem;
}
@media (max-width: 767.98px) {
  .audit-trail-page .mat-mdc-table thead tr th.mat-mdc-table-sticky,
  .smart-table .mat-mdc-table thead tr th.mat-mdc-table-sticky {
    position: static !important;
  }
}
.audit-trail-page .mat-mdc-table tbody tr,
.smart-table .mat-mdc-table tbody tr {
  background: #ffffff;
}
.audit-trail-page .mat-mdc-table tbody tr.expand-expanded-row,
.smart-table .mat-mdc-table tbody tr.expand-expanded-row {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  background-color: #f3fafd !important;
}
.audit-trail-page .mat-mdc-table tbody tr td,
.smart-table .mat-mdc-table tbody tr td {
  padding: 1rem 0.9375rem;
  border: none;
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  max-width: 210px;
  font-family: "Graphik Regular";
}
.audit-trail-page .mat-mdc-table tbody tr td .inner-text,
.smart-table .mat-mdc-table tbody tr td .inner-text {
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.audit-trail-page .mat-mdc-table tbody tr td .smart-icon-trash,
.smart-table .mat-mdc-table tbody tr td .smart-icon-trash {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #0172a6;
}
.audit-trail-page .mat-mdc-table tbody tr td .trash-disable,
.smart-table .mat-mdc-table tbody tr td .trash-disable {
  font-size: 1rem;
  line-height: 1.5rem;
  border-color: transparent;
  color: #666666;
}
.audit-trail-page .mat-mdc-table tbody tr td a.download-link,
.smart-table .mat-mdc-table tbody tr td a.download-link {
  color: #0172a6;
  overflow: auto;
}
.audit-trail-page .mat-mdc-table tbody tr td .form-control,
.smart-table .mat-mdc-table tbody tr td .form-control {
  padding: 0.25rem 0.75rem;
}
.audit-trail-page .mat-mdc-table tbody tr td .expand-element-detail.expanded,
.smart-table .mat-mdc-table tbody tr td .expand-element-detail.expanded {
  box-shadow: inset 0 0px 2px rgba(0, 0, 0, 0.5);
}
.audit-trail-page .mat-mdc-table tbody tr td .expand-element-detail.expanded .detail-cell .title,
.smart-table .mat-mdc-table tbody tr td .expand-element-detail.expanded .detail-cell .title {
  color: #666666;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.audit-trail-page .mat-mdc-table tbody tr td .expand-element-detail.expanded .detail-cell .value,
.smart-table .mat-mdc-table tbody tr td .expand-element-detail.expanded .detail-cell .value {
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.audit-trail-page .mat-mdc-table tbody tr td.grid-filter-message,
.smart-table .mat-mdc-table tbody tr td.grid-filter-message {
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.audit-trail-page .mat-mdc-table tbody tr td.talent-grid-filter-message,
.smart-table .mat-mdc-table tbody tr td.talent-grid-filter-message {
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: #666666;
}
@media (max-width: 767.98px) {
  .audit-trail-page .mat-mdc-table tbody tr td.mat-mdc-table-sticky,
  .smart-table .mat-mdc-table tbody tr td.mat-mdc-table-sticky {
    position: static !important;
  }
}
.audit-trail-page .mat-mdc-table tbody tr:nth-child(odd),
.smart-table .mat-mdc-table tbody tr:nth-child(odd) {
  background: #fafafa;
}
.audit-trail-page .mat-mdc-table tbody tr:nth-child(odd).expand-element-row,
.smart-table .mat-mdc-table tbody tr:nth-child(odd).expand-element-row {
  background: #fff !important;
}
td .audit-trail-page .mat-mdc-table tbody tr:nth-child(odd).mat-mdc-table-sticky,
td .smart-table .mat-mdc-table tbody tr:nth-child(odd).mat-mdc-table-sticky {
  background-color: #fafafa;
}
.audit-trail-page .mat-mdc-table tbody tr.expand-element-row:nth-child(4n+1),
.smart-table .mat-mdc-table tbody tr.expand-element-row:nth-child(4n+1) {
  background-color: #fafafa !important;
}
.audit-trail-page .mat-mdc-table tbody tr .active,
.smart-table .mat-mdc-table tbody tr .active {
  background-color: #DBF2FC;
}
.audit-trail-page .mat-mdc-table tbody tr .active.form-check-input[type=radio]:checked,
.smart-table .mat-mdc-table tbody tr .active.form-check-input[type=radio]:checked {
  background-color: #0f72a6;
}
.audit-trail-page.inline-editable tr td,
.smart-table.inline-editable tr td {
  vertical-align: top;
  max-width: fit-content !important;
}

.indicator-table {
  padding-top: 3px;
}
.indicator-table::before {
  position: absolute;
  content: "";
  left: 0;
  height: 3px;
  right: 1px;
  background-color: #333333;
  z-index: 2;
  top: 0;
}
.indicator-table.red::before {
  background-color: #EF1E40;
}
.indicator-table.green::before {
  background-color: #50A375;
}
.indicator-table.blue::before {
  background-color: #08103a;
}
.indicator-table.seablue::before {
  background-color: #15B9BF;
}
.indicator-table.blue::before, .indicator-table.blue::after {
  background-color: #216AA9;
}
.indicator-table.orange::before {
  background-color: #B48F46;
}
.indicator-table.green2::before, .indicator-table.green2::after {
  background-color: #339999;
}
.indicator-table.purple::before {
  background-color: #993399;
}
.indicator-table.green3::before {
  background-color: #669999;
}
.indicator-table.red5::before {
  background-color: #F01E40;
}

mat-tooltip-component .mdc-tooltip__surface {
  letter-spacing: normal !important;
}
mat-tooltip-component .offwhite-tooltip .mdc-tooltip__surface {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #666666;
  background-color: #f7f7f7;
  border: 1px solid #cccccc;
  padding: 0.375rem;
  max-width: 355px;
  white-space: pre-line;
  max-height: max-content;
}
@media (max-width: 767.98px) {
  mat-tooltip-component .offwhite-tooltip .mdc-tooltip__surface {
    max-height: 200px;
    overflow: auto;
  }
}
mat-tooltip-component .offwhite-tooltip.mandatory-tooltip .mdc-tooltip__surface {
  color: #CC3232;
}
mat-tooltip-component .profile-tooltip .mdc-tooltip__surface {
  font-family: "Graphik Regular";
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding: 0.5rem 1rem;
  color: #000000;
  border-radius: 2px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 0;
  max-width: fit-content;
}

.smart-btn-link {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #0172a6;
}
.smart-btn-link .smart-btn-icon {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.table-action-btn {
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.smart-slide {
  position: relative;
}
.smart-slide .mdc-switch {
  --mdc-switch-track-width: 40px;
  --mdc-switch-handle-width: 16px;
  --mdc-switch-selected-icon-size: 14px;
  --mdc-switch-track-height: 20px;
  --mdc-switch-track-shape: 10px;
  --mdc-switch-handle-height: 16px;
  --mdc-switch-unselected-icon-size: 14px;
  --mdc-switch-disabled-handle-opacity: 0.5;
  --mdc-switch-disabled-selected-icon-opacity: 0.5;
  --mdc-switch-disabled-track-opacity: 0.5;
}
.smart-slide .mdc-switch:enabled .mdc-switch__track::before, .smart-slide .mdc-switch:disabled .mdc-switch__track::before {
  background-color: transparent !important;
  border: 1px solid #666666;
}
.smart-slide .mdc-switch:enabled .mdc-switch__track::after, .smart-slide .mdc-switch:disabled .mdc-switch__track::after {
  background-color: transparent !important;
  border: 1px solid #337ab7;
}
.smart-slide .mdc-switch.mdc-switch--unselected .mdc-switch__handle {
  left: 2px;
}
.smart-slide .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon, .smart-slide .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
  fill: transparent;
}
.smart-slide .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after, .smart-slide .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__handle::after {
  background: #666666 !important;
}
.smart-slide .mdc-switch.mdc-switch--selected .mdc-switch__handle {
  left: -2px;
}
.smart-slide .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after, .smart-slide .mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after {
  background: #337ab7 !important;
}
.smart-slide .mdc-switch:enabled:focus .mdc-switch__ripple::before, .smart-slide .mdc-switch:enabled:focus .mdc-switch__ripple::after {
  background: #337ab7 !important;
}
.smart-slide.cdk-keyboard-focused .mdc-switch__handle, .smart-slide:hover .mdc-switch__handle {
  outline: 2px solid #006796;
  outline-offset: 1px;
}
.smart-slide .mdc-form-field--align-end > label {
  margin-right: 10px;
}

.mat-sort-header.cdk-keyboard-focused .mat-sort-header-container {
  outline: 2px solid #ffffff;
  outline-offset: 1px;
  border: 0 !important;
}
.mat-sort-header .mat-sort-header-arrow {
  opacity: 1 !important;
  font-family: "smart-icon" !important;
}
.mat-sort-header .mat-sort-header-arrow div {
  display: none;
}
.mat-sort-header .mat-sort-header-arrow::before {
  position: relative;
  top: -3px;
}
.mat-sort-header[aria-sort=none] .mat-sort-header-arrow::before {
  content: "\e91e";
}
.mat-sort-header[aria-sort=ascending] .mat-sort-header-arrow::before {
  content: "\e91d";
  font-size: 6px;
  transform: rotate(180deg);
  top: 0;
}
.mat-sort-header[aria-sort=descending] .mat-sort-header-arrow::before {
  content: "\e91d";
  font-size: 6px;
}

.confirm-popup {
  position: relative !important;
}
.confirm-popup .close-btn {
  top: 12px;
  right: 6px;
  color: #666666;
  z-index: 1;
}
@media (min-width: 576px) {
  .confirm-popup .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    padding: 2.75rem 3.5rem;
  }
}
.confirm-popup .mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__title {
  padding: 0rem;
  color: #08103a !important;
  font-family: "Graphik Semibold" !important;
  font-size: 1.5rem;
  line-height: 2.25rem;
}
.confirm-popup .mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__title::before {
  display: none;
}
.confirm-popup .mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__content {
  padding: 0rem;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  color: #333333;
}
.confirm-popup .mat-mdc-dialog-container .mat-mdc-dialog-surface .mdc-dialog__actions {
  padding: 0rem;
}
@media (max-width: 575.98px) {
  .confirm-popup .mat-mdc-dialog-container {
    max-height: 53vh;
  }
}
.confirm-popup.widthchange .mat-mdc-dialog-container {
  padding: 30px 30px 50px;
}
.confirm-popup.uncheckPopup .mat-mdc-dialog-container .mat-mdc-dialog-content .snowLink {
  text-decoration: none;
}
.confirm-popup.uncheckPopup .mat-mdc-dialog-container .smart-icon-close {
  display: none;
}

.smart-label {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #666666;
}

/*-----------------------------------*/
.donut-tooltip {
  width: 10px;
  height: 10px;
  background: #db8905;
  margin-right: 10px;
  float: left;
  margin-top: 6px;
  margin-left: 6px;
}

.donut-tooltip.tagged {
  background: #db8905;
}

.donut-tooltip.active {
  background: #ef8d0a;
}

.donut-tooltip.refusal {
  background: #fca822;
}

.donut-tooltip.rejected {
  background: #fcba4f;
}

.donut-tooltip.ijp {
  background: #ffca7d;
}

/*-----------------------------------*/
.mat-mdc-menu-panel.table-filter-menu {
  border-radius: 0px;
}
.mat-mdc-menu-panel.table-filter-menu .mat-mdc-menu-content {
  padding: 12px 16px;
  min-width: 140px;
}
.mat-mdc-menu-panel.table-filter-menu .mat-mdc-menu-content .mat-mdc-menu-item:hover {
  background-color: #ffffff;
}
.mat-mdc-menu-panel.table-filter-menu .mat-mdc-menu-content .mat-mdc-menu-item {
  height: auto;
  padding: 0;
  line-height: 1.5;
}
.mat-mdc-menu-panel.table-filter-menu .mat-mdc-menu-content button {
  color: #ffffff;
  background-color: #0172a6;
  border-radius: 2px;
  border: 0;
  padding: 0px 20px;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.filter-style {
  color: #ffffff;
}

.toaster-style {
  max-width: none !important;
}

.mat-mdc-snack-bar-container {
  padding: 24px;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  padding: 0.625rem 1rem;
  box-shadow: none;
  min-height: 2.5rem;
}
@media (max-width: 767.98px) {
  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    min-width: 250px !important;
  }
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface .mdc-snackbar__label {
  padding: 0;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface .customised-toast {
  position: relative;
  padding-right: 2rem;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface .customised-toast .mdc-snackbar__label {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #000000;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface .customised-toast .btn-undo {
  display: none;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 4px;
  margin-left: 8px;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface .customised-toast .close-toast {
  padding: 2px;
  right: 0;
  top: 0;
}
.mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  background-color: #3E8629;
}
.mat-mdc-snack-bar-container.success .mdc-snackbar__surface .customised-toast .mdc-snackbar__label {
  color: #ffffff;
}
.mat-mdc-snack-bar-container.success .mdc-snackbar__surface .customised-toast .close-toast {
  color: #ffffff;
}
.mat-mdc-snack-bar-container.warning .mdc-snackbar__surface {
  background-color: #ffcc66;
}
.mat-mdc-snack-bar-container.danger .mdc-snackbar__surface {
  background-color: #CC3232;
}
.mat-mdc-snack-bar-container.danger .mdc-snackbar__surface .customised-toast .mdc-snackbar__label {
  color: #ffffff;
}
.mat-mdc-snack-bar-container.danger .mdc-snackbar__surface .close-toast {
  color: #ffffff;
}

.statusColorStyles {
  color: #ffffff;
}
.statusColorStyles.pendingColor {
  background-color: #dc3545;
}
.statusColorStyles.approvedColor {
  background-color: #3e8629;
}
.statusColorStyles.rejectedColor {
  background-color: #343a40;
}
.statusColorStyles.deletedColor {
  background-color: #ffc107;
  color: #333333;
}

.resource-detail-tab-view {
  background-color: #000033;
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header {
  padding: 0 5%;
  padding-right: 80px;
  border: none;
}
@media (min-width: 768px) {
  .resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header {
    padding-right: 160px;
  }
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
  padding-right: 80px;
}
@media (min-width: 768px) {
  .resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
    padding-right: 160px;
  }
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab {
  opacity: 1;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  min-width: 80px;
  border: 2px solid transparent;
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  color: #ffffff !important;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  letter-spacing: normal;
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active {
  background-color: #ffffff;
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active .mdc-tab__text-label {
  font-family: "Graphik Medium";
  color: #08103a !important;
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  overflow: auto;
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab.cdk-keyboard-focused, .resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab:hover, .resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab:focus {
  border: 2px solid #006796;
}
.resource-detail-tab-view mat-tab-group.profile-tab > mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab .mdc-tab-indicator .mdc-tab-indicator__content {
  display: none;
}
.resource-detail-tab-view mat-tab-group.profile-tab .mat-mdc-tab-body-wrapper mat-tab-body {
  height: 100%;
  background: #ffffff;
}
.resource-detail-tab-view mat-tab-group.profile-tab .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  border-color: #999999;
}
.resource-detail-tab-view mat-tab-group.profile-tab .mat-mdc-tab-header-pagination-chevron {
  border-color: #ffffff;
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header {
  border: 0;
}
@media (min-width: 576px) {
  .resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header {
    padding-right: 0;
    padding-left: 0;
  }
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 15px;
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab {
  opacity: 1;
  min-width: auto;
  height: auto;
  padding: 0.3125rem 0;
  margin-right: 30px;
  border: 2px solid transparent;
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  color: #08103a !important;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-transform: capitalize;
  letter-spacing: normal;
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active {
  background: #ffffff;
  border-bottom: 2px solid #08103a;
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active .mdc-tab__text-label {
  color: #08103a !important;
  font-family: "Graphik Medium";
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  overflow: auto;
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab.cdk-keyboard-focused, .resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab:hover, .resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab:focus {
  border: 2px solid #006796;
}
.resource-detail-tab-view mat-tab-group.learning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab .mdc-tab-indicator .mdc-tab-indicator__content {
  display: none;
}
.resource-detail-tab-view .mat-mdc-tab-body-wrapper mat-tab-body {
  height: 100%;
  background: #ffffff;
}
.resource-detail-tab-view .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  border-color: #999999;
}
.resource-detail-tab-view .mat-mdc-tab-header-pagination-chevron {
  border-color: #ffffff;
}

mat-tab-group.traning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
  border-bottom: 0;
}
mat-tab-group.traning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab {
  padding: 0.3125rem 0.625rem;
  margin-right: 0;
  border: 0;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}
mat-tab-group.traning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  color: #666666 !important;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-transform: capitalize;
  letter-spacing: normal;
}
mat-tab-group.traning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active {
  background: #333333;
  border-bottom: 2px solid #08103a;
  border-bottom: 0;
}
mat-tab-group.traning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active .mdc-tab__text-label {
  color: #ffffff !important;
}
mat-tab-group.traning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-left: 1px solid #333333;
}
mat-tab-group.traning-tab mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid #333333;
}

.comments-option-section {
  top: -12px;
  right: 5%;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .comments-option-section {
    top: 0px;
  }
}
.comments-option-section .comments-icon {
  font-size: 20px;
  color: #337ab7;
}
@media (min-width: 768px) {
  .comments-option-section .comments-icon {
    font-size: 30px;
  }
}
.comments-option-section .more-btn {
  font-size: 20px;
  color: #ffffff;
  height: 36px;
}
@media (min-width: 768px) {
  .comments-option-section .more-btn {
    font-size: 30px;
  }
}
.comments-option-section .mat-mdc-menu-content {
  min-width: 160px;
}

.mandatory-field {
  color: #CC3232;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.form-label {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #666666;
}

/*------- Custom scroll css ---------*/
.custom-overflow-scroll::-webkit-scrollbar-thumb {
  background-color: #08103a;
  border-radius: 4px;
}

.custom-overflow-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 13px #cccccc;
}

.custom-overflow-scroll::-webkit-scrollbar,
.access-modal .mat-mdc-dialog-container::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/*-----------------------------------*/
.form-control {
  color: #333333;
  border-radius: 2px;
  border-color: #929292;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.form-control.error {
  border-color: #dc3545;
}
.form-control:disabled {
  background-color: #f2f2f2;
  color: #666666;
  border-color: #929292;
}
.form-control::placeholder {
  color: #757575 !important;
}
.form-control:-ms-input-placeholder {
  color: #757575;
}
.form-control::-ms-input-placeholder {
  color: #757575;
}

.resource-details-tab-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tooltip {
  position: absolute;
  height: auto;
  padding: 5px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.custom-mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}
.custom-mat-accordion .mat-expansion-panel {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.custom-mat-accordion .mat-expansion-panel .mat-expanded.mat-expansion-panel-header .title .expand-icon::before {
  content: "\e927";
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  height: auto;
  padding: 0;
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header.cdk-focused {
  border: 1px solid #000000;
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  margin-right: 0;
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
  height: auto;
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
  font-family: "Graphik Medium";
  background-color: #fafafa;
  padding: 8px 24px;
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title .expand-icon {
  font-size: 14px;
  color: #0172a6;
  font-family: "smart-icon" !important;
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title .expand-icon::before {
  content: "\e910";
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}
.custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0;
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expanded.mat-expansion-panel-header .sub-title .expand-icon {
  transform: rotate(180deg);
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .sub-title {
  font-family: "Graphik Regular";
  font-size: 0.875rem;
  line-height: 1.3125rem;
  background-color: #fafafa;
  padding: 4px 24px;
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .sub-title .expand-icon {
  color: #0172a6;
  font-family: "smart-icon" !important;
  transform: rotate(90deg);
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-header .sub-title .expand-icon::before {
  content: "\e928";
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0;
  font-family: "Graphik Regular";
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .inner-panel-body {
  padding: 8px 24px;
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .inner-panel-body .checkbox-container {
  padding-left: 22px;
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .inner-panel-body .checkbox-container .checkmark {
  height: 12px;
  width: 12px;
  top: 3px;
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .inner-panel-body .checkbox-container .checkmark:after {
  left: 3px;
  top: 0;
  width: 4px;
  height: 9px;
  border-width: 0 2px 2px 0;
}
.custom-mat-accordion .sub-custom-mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body .inner-panel-body .show-more {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #0172a6;
}

.search-mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}
.search-mat-accordion .mat-expansion-panel {
  border-radius: 0 !important;
  box-shadow: 0px 2px 5px -3px #000 !important;
}
.search-mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}

.text-red,
.link-danger {
  color: #CC3232 !important;
}

.link-warning {
  color: #827800 !important;
}

.footer-band {
  position: fixed;
  z-index: 99999;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #ffffff;
  max-height: 0;
  padding: 0 50px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border: 1px solid #F1F1F3;
}
.footer-band.isOpen {
  max-height: 150px;
  padding: 16px;
  opacity: 1;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.07);
}
@media (min-width: 576px) {
  .footer-band.isOpen {
    padding: 14px 50px;
  }
}

.chip-container {
  margin-top: 22px;
}
.chip-container .chip {
  display: inline-block;
  font-size: 0.75rem;
  background-color: #f2f2f2;
  color: #111111;
  padding: 2px 4px;
}

.closebtn {
  color: #ee1e40;
  font-weight: 200;
  float: right;
  font-size: 7px;
  cursor: pointer;
}
.closebtn:hover {
  outline: 2px solid #3399cc;
  outline-offset: 1px;
}

.select-option-wrap.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  height: auto;
}
.select-option-wrap.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .text-truncate {
  white-space: normal;
  text-overflow: unset;
  overflow: unset;
}
.select-option-wrap.selection-control .ng-value-label {
  overflow: hidden;
}

.filter-card-container {
  position: fixed;
  background-color: #ffffff;
  top: 0;
  right: 0;
  z-index: 101;
  width: 267px;
  border: 1px solid #F1F1F3;
  border-radius: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
}
.filter-card-container .closebtn {
  color: #3399cc;
  font-size: 0.875rem;
  cursor: pointer;
}
.filter-card-container .closebtn:hover {
  outline: 2px solid #3399cc;
  outline-offset: 1px;
}
.filter-card-container .filter-card-header {
  font-size: 1.5rem;
  font-family: "Graphik Medium";
  padding-left: 25px;
  padding-bottom: 24px;
  padding-top: 24px;
  background-color: #ffffff;
  border: 1px solid #F1F1F3;
}
.filter-card-container .filter-card-body {
  overflow-y: auto;
  height: 80vh;
}
.filter-card-container .filter-card-body .search-container {
  padding: 12px 24px;
}
.filter-card-container .filter-card-body .search-container .form-control {
  padding: 3px 12px;
}
.filter-card-container .filter-card-body .filter-date-icon {
  top: 46px;
  right: 35px;
}
.filter-card-container .filter-card-footer {
  background-color: #ffffff;
  border-top: 1px solid #F1F1F3;
}

.resource-mangement-filter .filter-card-container .filter-card-body {
  height: calc(100vh - 188px);
}

.tags-btn {
  color: #f4526b;
  font-family: "Graphik Medium";
  text-transform: uppercase;
}

.tags-list {
  margin-top: 6px;
}
.tags-list.tags-list-added span {
  color: #fafafa;
  background-color: #383f61;
}
.tags-list span {
  padding: 5px;
  color: #111111;
  font-size: 0.75rem;
  line-height: 1.125rem;
  background-color: #383f61;
  margin-right: 6px;
  margin-bottom: 6px;
}
.tags-list span .btn-link {
  font-size: 0.5rem;
  color: #f4526b;
}

.mat-mdc-paginator {
  color: #666666 !important;
  letter-spacing: normal !important;
}
.mat-mdc-paginator .mat-mdc-icon-button {
  background-color: #0172a6;
  color: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
}
.mat-mdc-paginator .mat-mdc-icon-button:not(:last-child) {
  margin-right: 1.5rem;
}
.mat-mdc-paginator .mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 1.5rem;
  height: 1.5rem;
}
.mat-mdc-paginator .mat-mdc-paginator-icon {
  width: 1.25rem;
  fill: #ffffff;
}
.mat-mdc-paginator .mat-mdc-icon-button[disabled] {
  background-color: #eaeaea;
}
.mat-mdc-paginator .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: #cccccc;
}

.error-validation-msg {
  font-size: 12px;
  color: #CC3232;
}

.details-text h2 {
  font-family: "Graphik Regular";
  font-size: 1rem;
  line-height: 1.5rem;
  color: #dc3545;
}
.details-text .details-sub-text {
  color: #4c4c4c;
  padding-bottom: 1.875rem;
}

.rotate-180 {
  transform: rotate(180deg);
}

.sticky-row th.mat-mdc-table-sticky {
  outline: 1px solid #ffffff;
  border-right: 0px;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.basic-details-tab mat-tab-group {
  flex-direction: column;
}
@media (min-width: 768px) {
  .basic-details-tab mat-tab-group {
    flex-direction: row;
  }
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
  flex-direction: row;
}
@media (min-width: 768px) {
  .basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
    flex-direction: column;
  }
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab {
  position: relative;
  padding: 0 8px 0 12px;
  min-width: 236px;
  border: 1px solid #cccccc;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  height: 40px !important;
  background: #ffffff;
  color: #333333;
  opacity: 1;
  text-transform: uppercase;
  justify-content: left;
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab {
    padding: 0 32px 0 16px;
  }
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab:not(:last-child) {
  border-bottom: transparent;
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab--active {
  background-color: #EFEFF0;
  font-family: "Graphik Semibold";
  color: #333333;
}
@media (min-width: 768px) {
  .basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab--active::after {
    position: absolute;
    font-family: "smart-icon";
    content: "\e923";
    transform: rotate(-90deg);
    right: 12px;
    top: 8px;
  }
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #333333;
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab.cdk-keyboard-focused, .basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab:hover, .basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab:focus {
  border: 1px solid #006796;
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab .mdc-tab__content {
  justify-content: start;
  width: 100%;
}
.basic-details-tab mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mdc-tab-indicator .mdc-tab-indicator__content {
  display: none;
}
.basic-details-tab mat-tab-group .mat-mdc-tab-body-wrapper {
  width: 100%;
}
.basic-details-tab mat-tab-group .mat-mdc-tab-header-pagination-chevron {
  border-color: #000000 !important;
}

.error-validation {
  border-color: #CC3232;
}
.error-validation .ng-select-container {
  border-color: #CC3232 !important;
}

.custom-checkbox {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-checkbox .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0f72a6;
  border: 1px solid #666666;
}
.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #999999;
  border: 1px solid #666666;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  position: absolute;
  display: block;
  left: -16px;
  top: 4px;
  width: 4px;
  height: 9px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  outline: 2px solid #006796;
  outline-offset: 1px;
}
.custom-checkbox .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  display: inline-block;
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.375rem;
  cursor: pointer;
}
.custom-checkbox .custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: -20px;
  display: block;
  width: 12px;
  height: 12px;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #666666 solid 1px;
  border-radius: 2px;
}
.custom-checkbox .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -20px;
  display: block;
  width: 12px;
  height: 12px;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.basic-details-container .label-heading {
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #333333;
  font-family: "Graphik Regular";
}

.nodata-container {
  padding: 0 3%;
}
.nodata-container .label-heading1 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-family: "Graphik Regular";
}

.expandable-tab-container mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mat-mdc-focus-indicator {
  padding: 1px;
  height: 34px;
  margin-right: 1.5rem;
}
@media (min-width: 576px) {
  .expandable-tab-container mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mat-mdc-focus-indicator {
    margin-right: 2.5rem;
  }
}
.expandable-tab-container mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__text-label {
  text-transform: none;
}
.expandable-tab-container mat-tab-group mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mdc-tab-indicator .mdc-tab-indicator__content {
  display: block !important;
  border: 2px solid #586774;
}
.expandable-tab-container mat-tab-group .mat-mdc-tab-body-wrapper {
  margin-bottom: 0.5rem;
}
.expandable-tab-container mat-tab-group .mat-mdc-tab-body-wrapper mat-tab-body .mat-mdc-tab-body-content {
  overflow: hidden;
}

.btn-link {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #0172a6;
}

.indicator-tabs .mat-mdc-tab-body.mat-mdc-tab-body-active {
  z-index: 101;
}

.action-right {
  right: 0;
  z-index: 2;
  top: -32px;
}
@media (min-width: 1200px) {
  .action-right {
    top: 0;
    right: 34px;
  }
}

.section-header {
  font-family: "Graphik Semibold";
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.switch-toggle .swicth-label {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #666666;
}

.smart-progress-bar .smart-probability-bar {
  height: 16px;
  border-radius: 2px;
}
.smart-progress-bar .bar-filled {
  background-color: #db8905;
}
.smart-progress-bar .progress-bar-success {
  background-color: #3E8629;
}
.smart-progress-bar .progress-bar-warning {
  background-color: #d87923;
}
.smart-progress-bar .progress-bar-danger {
  background-color: #dc3545;
}
.smart-progress-bar .progress-bar-label {
  color: #666666;
}
.smart-progress-bar .progress-bar-tagged {
  background: #25537A;
}
.smart-progress-bar .progress-bar-untagged {
  background: #EE466E;
}
.smart-progress-bar .progress-bar-lightblue {
  background: #00A1CC;
}
.smart-progress-bar .progress-bar-yellow {
  background: #d87923;
}

.dropdown-tags {
  font-size: 0.75rem;
  line-height: 1.125rem;
  background-color: #f2f2f2;
  color: #333333;
  border-radius: 16px;
}
.dropdown-tags.tag-block {
  max-width: 150px;
}
.dropdown-tags.tag-block .tag-content {
  max-width: 90%;
}
.dropdown-tags .smart-btn {
  font-size: 10px;
}

.input-group .form-control {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.smart-multi-chkbox-option.form-check .form-check-input {
  border: 1px solid #0f72a6;
  border-radius: 2px;
}
.smart-multi-chkbox-option .option-checked {
  background-color: #0f72a6;
}

.shared-lock-filter .filter-card-container .filter-card-body {
  height: calc(100vh - 148px);
}

.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}
.mat-mdc-autocomplete-panel .mdc-list-item__primary-text {
  letter-spacing: normal !important;
}
@media (max-width: 575.98px) {
  .mat-mdc-autocomplete-panel .mdc-list-item__primary-text {
    white-space: normal !important;
  }
}
.mat-mdc-autocomplete-panel.auto-complete-search mat-option {
  height: 36px;
  line-height: 36px;
  min-height: auto;
}
.mat-mdc-autocomplete-panel.auto-complete-search mat-option .mdc-list-item__primary-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
@media (max-width: 575.98px) {
  .mat-mdc-autocomplete-panel.auto-complete-search mat-option .mdc-list-item__primary-text {
    white-space: normal !important;
  }
}
.mat-mdc-autocomplete-panel.auto-complete-search mat-option.mdc-list-item--disabled {
  color: #666666;
}
.mat-mdc-autocomplete-panel.auto-complete-search mat-option.mdc-list-item--disabled .mdc-list-item__primary-text {
  opacity: 1;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.info-icon {
  color: #0172a6;
  font-size: 0.875rem;
}

.filtered-tags {
  list-style: none;
}
.filtered-tags li {
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-bottom: 4px;
  color: #666666;
}
.filtered-tags li.filter-sub-title {
  font-family: "Graphik Bold";
}
.filtered-tags li.filter-items {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  background: #eaeaea;
  color: #333333;
  padding: 2px 8px;
}
.filtered-tags li.filter-items .btn {
  font-size: 8px;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-size: 0.875rem !important;
  line-height: 1.3125rem !important;
}

.reverse {
  transform: rotate(180deg);
}

.filtered-tags {
  list-style: none;
}
.filtered-tags li {
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-bottom: 4px;
  color: #666666;
}
.filtered-tags li.filter-sub-title {
  font-family: "Graphik Bold";
}
.filtered-tags li.filter-items {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  background: #eaeaea;
  color: #333333;
  padding: 2px 8px;
}
.filtered-tags li.filter-items .btn {
  font-size: 8px;
}

.hide-content {
  overflow: hidden;
  height: 28px;
}
.hide-content .show-more {
  top: -2px;
  right: 0;
  position: absolute;
}

.show-content {
  height: auto;
  margin-bottom: 20px;
  width: 100%;
  word-wrap: break-word;
}
.show-content .show-more-btn:before {
  display: none;
}
.show-content .show-more-btn .smart-icon-arrow-down {
  transform: rotate(180deg);
}

.show-more-btn {
  background: #0172a6;
  color: #ffffff;
  font-family: "Graphik Medium";
  height: 28px;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  border-left: 4px solid #ffffff !important;
}
.show-more-btn:before {
  content: "...";
}
.show-more-btn .smart-icon-arrow-down {
  font-size: 8px;
}

.filter-loader {
  background-color: #000000;
  opacity: 0.4;
  z-index: 999;
}
.filter-loader .spinner-border {
  color: #ffffff;
}

.mat-mdc-option .mat-mdc-option-pseudo-checkbox {
  display: none;
}

@media (max-width: 767.98px) {
  .mat-datepicker-content .mat-datepicker-close-button.cdk-focused {
    margin-top: -35px;
    margin-left: 16px;
  }
}

.info {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.smart-stepper .mat-horizontal-stepper-header-container {
  pointer-events: none;
  justify-content: center;
}
.smart-stepper .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
  padding: 0 10px;
}
@media (max-width: 767.98px) {
  .smart-stepper .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header {
    padding: 0 6px;
  }
}
.smart-stepper .mat-vertical-stepper-header {
  pointer-events: none;
  padding: 20px 10px;
}
.smart-stepper .mat-step-icon-state-done {
  background-color: #339900;
}
.smart-stepper .mat-stepper-horizontal-line {
  border-style: none;
  flex: none;
}
.smart-stepper .mat-step-icon-state-edit {
  background-color: #cccccc;
}
.smart-stepper .mat-step-icon {
  height: 16px;
  width: 16px;
}
.smart-stepper .mat-step-icon .mat-step-icon-content {
  font-size: 10px;
}
.smart-stepper .mat-step-icon .mat-icon {
  font-size: 12px;
  height: 12px;
  width: 12px;
}
.smart-stepper .mat-step-header .mat-step-text-label {
  font-family: "Graphik Regular";
  color: #000000;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.smart-stepper .mat-step-header .mat-step-label-selected {
  border-bottom: 3px solid #000000;
}
.smart-stepper .mat-step-header:not(:last-child)::after {
  font-family: "smart-icon" !important;
  content: "\e923";
  position: relative;
  left: 10px;
  transform: rotate(270deg);
  font-size: 10px;
}
@media (max-width: 767.98px) {
  .smart-stepper .mat-step-header:not(:last-child)::after {
    left: 6px;
  }
}
.smart-stepper .mat-vertical-content-container {
  margin-left: 20px;
}
.smart-stepper .mat-vertical-content-container::before {
  left: -4px;
}

.stepper-footer {
  border-top: 1px solid #C5C3C3;
  margin-top: 40px;
  padding-top: 30px;
}
@media (max-width: 767.98px) {
  .stepper-footer {
    margin-top: 20px;
    padding-top: 15px;
  }
}

.smart-vertical-stepper.mat-stepper-vertical .mat-step {
  font-family: "Graphik Regular";
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-vertical-stepper-header {
  height: 32px;
  padding: 0 0 12px 0;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header {
  font-family: "Graphik Medium";
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header + .mat-vertical-content-container .mat-vertical-stepper-content {
  overflow: visible !important;
  visibility: visible !important;
  height: unset !important;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header + .mat-vertical-content-container .mat-vertical-stepper-content .mat-vertical-content {
  padding: 0 12px 12px 24px;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header[aria-disabled=true] {
  color: #c1c1c1;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header[aria-disabled=true] + .mat-vertical-content-container .mat-vertical-stepper-content .stepper-form {
  visibility: hidden;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header[aria-disabled=true] + .mat-vertical-content-container .mat-vertical-stepper-content .stepper-form-label {
  color: #C0C1C0;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-icon {
  border: 1px solid #c1c1c1;
  background-color: transparent;
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 16px;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-icon.mat-step-icon-state-edit, .smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-icon.mat-step-icon-state-done {
  border-color: transparent;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-icon.mat-step-icon-state-edit::after, .smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-icon.mat-step-icon-state-done::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  background-color: #0172a6;
  border-radius: 50%;
  left: 1px;
  top: 1px;
  outline: 1px solid #0172a6;
  outline-offset: 1px;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-icon .mat-step-icon-content {
  display: none;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-vertical-content-container {
  margin-left: 6px;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-stepper-vertical-line::before {
  border-color: #c1c1c1;
  top: -22px;
  bottom: -10px;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header {
  pointer-events: none;
  cursor: pointer;
  background: transparent;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label {
  font-family: "Graphik Medium";
  position: relative;
  display: flex;
  align-items: center;
  width: 300px;
  height: 100%;
  color: #c1c1c1;
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label::before {
  position: absolute;
  content: "";
  border: 1px solid #c1c1c1;
  border-right: 0;
  left: 0;
  right: 16px;
  top: 0;
  bottom: 0;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label::after {
  position: absolute;
  content: "";
  right: 5px;
  width: 24px;
  height: 24px;
  border-top: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
  transform: rotate(135deg);
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label.mat-step-label-active {
  color: #ffffff;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label.mat-step-label-active .mat-step-text-label {
  border-color: #0172a6;
  z-index: 1;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label.mat-step-label-active::before {
  border-color: #0172a6;
  background-color: #0172a6;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label.mat-step-label-active::after {
  border-top-color: #0172a6;
  border-left-color: #0172a6;
  background-color: #0172a6;
}
.smart-vertical-stepper.mat-stepper-vertical .mat-step .mat-step-header .mat-step-label .mat-step-text-label {
  margin-left: 16px;
}